import React, {useContext} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faRocket} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import facebookLogo from '../assets/facebook.png';
import instaLogo from '../assets/instagram.png';
import linkedinLogo from '../assets/linkedin.webp';
import '../css/Footer.css'
import ThemeContext from "../context/ThemeContext";

function Footer() {
    const {darkMode} = useContext(ThemeContext);
    return (

        <footer className={darkMode?"d-flex flex-wrap justify-content-between align-items-center py-3 p-3 custom-footer-dark":"d-flex flex-wrap justify-content-between align-items-center py-3 p-3 custom-footer"}
        >
            <div className="col-md-4 d-flex align-items-center">
                <div className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
                    <FontAwesomeIcon className="me-2 mb-md-0 text-muted text-decoration-none lh-1" icon={faRocket}/>Propulsé
                    par <span className="text-bopper"><a href="https://vitalytech.fr/" target="_blank"  rel={"noreferrer"} style={{
                    textDecoration: "none",
                    color: "#29abe2"
                }}><strong>Vitalytech</strong></a></span>
                </div>
            </div>


            <div className="col-md-4 d-flex align-items-center">
                <Link className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1 " to="/cgu">
                    CGU
                </Link>
                <Link className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1 " to="/privacy-policy">
                    Politique de confidentialité
                </Link>
            </div>

            <ul className="nav justify-content-end list-unstyled d-flex div-reseaux">
                <li className="ms-1"><a className="text-muted" href="https://www.facebook.com/Bopper.Officiel/"
                                        target="_blank" rel={"noreferrer"}>
                    <img className="logo-reseaux img-fluid" src={facebookLogo} alt={"Facebook"}/>
                </a></li>
                <li className="ms-1"><a className="text-muted" href="https://www.instagram.com/bopper.officiel/"
                                        target="_blank" rel={"noreferrer"}>
                    <img className="logo-reseaux img-fluid" src={instaLogo} alt={"Instagram"}/>
                </a></li>
                <li className="ms-1"><a className="text-muted" href="https://fr.linkedin.com/company/bopperofficiel"
                                        target="_blank" rel={"noreferrer"}>
                    <img className="logo-reseaux img-fluid" src={linkedinLogo} alt={"LinkedIn"}/>
                </a></li>
            </ul>
        </footer>
    );
}

export default Footer;