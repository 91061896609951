import '../css/Banniere.css';
import React, {useContext} from "react";
import navbarContext from "../context/navbarContext";

import BopperLogo from "../assets/bopper-logo-white.webp";
import banniereLight from '../assets/banniere_light.mp4';
import banniereDarkVideo from '../assets/banniere_dark.mp4';

import arrowDown from "../assets/arrow-down.json"
import Lottie from "lottie-react";
import ios from "../assets/ios.webp";
import android from "../assets/android.webp";
import androidWhite from '../assets/android-white.webp';
import iosWhite from '../assets/ios-white.webp';
import ThemeContext from "../context/ThemeContext";

const detectOs = () => {
    const OS = ['Android', 'iPhone', 'Windows', 'Mac', 'Linux']
    const ua = window.navigator.userAgent
    for(const currOs of OS) {
        if (ua.toLowerCase().includes(currOs.toLowerCase())) {
            return currOs;
        }
    }
}

const currentOs = detectOs();

function Banniere() {

    const {hauteurNavbar} = useContext(navbarContext);
    const {darkMode} = useContext(ThemeContext);

    return (
        <div>
            <div className="banniere">

                 <video controls={false} autoPlay={true} loop={true} muted={true} playsInline={true} className="background-dl imgg " style={{display:darkMode?'none':'block'}}>
                    <source src={banniereLight} type="video/mp4"/>
                </video>
                <video controls={false} autoPlay={true} loop={true} muted={true} playsInline={true} className="background-dl imgg" style={{display:darkMode?'block':'none'}}>
                    <source src={banniereDarkVideo} type="video/mp4"/>
                </video>

                <div className="telechargement" style={{
                    paddingTop: hauteurNavbar
                }}>

                        <img
                            src= {BopperLogo}
                            alt="Le logo"
                        className="img-fluid logo"
                        />

                    <div className="row-dl-accueil">
                        <div className="col-dl-accueil">
                            <a className="btn-dl-accueil" target="_blank" href="https://apps.apple.com/fr/app/bopper/id1578669466" rel={"noreferrer"}>
                                <img className="img-fluid" src={darkMode?iosWhite:ios} alt={"Télécharger sur apple store"}/>
                            </a>
                            <a className="btn-dl-accueil" target="_blank" href="https://play.google.com/store/apps/details?id=fr.bopper&gl=FR" rel={"noreferrer"}>
                                <img className="img-fluid" src={darkMode?androidWhite:android} alt={"Télécharger sur play store"}/>
                            </a>
                            <button type="button" className="btn-dl-mobile" onClick={() => {window.location = currentOs.toLowerCase() === 'iPhone'.toLowerCase() || currentOs.toLowerCase() === 'Mac'.toLowerCase() ? 'https://apps.apple.com/fr/app/bopper/id1578669466' : 'https://play.google.com/store/apps/details?id=fr.bopper&gl=FR' }}>
                                <img alt="Télécharger l'application" className="img-button-download-mobile img-fluid" src={currentOs.toLowerCase() === "iphone" || currentOs.toLowerCase() === "mac" ?darkMode?iosWhite:ios : darkMode?androidWhite:android} />
                            </button>
                        </div>
                    </div>
                    <Lottie className="button-down" autoplay={true} loop={true} animationData={arrowDown}/>


                </div>

            </div>
        </div>

    );
}

export default Banniere;