import Banniere from "./Banniere";
import SectionApplication from "./SectionApplication";
import SectionTestDrive from "./SectionTestDrive";
import SectionNotreObjectif from "./SectionNotreObjectif";
import Image from '../assets/iphone.webp';
import ImageDark from '../assets/iphone-dark.webp';
import React, {useCallback, useContext, useEffect, useLayoutEffect, useState} from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {useMediaQuery} from "react-responsive";
import ThemeContext from "../context/ThemeContext";
import SectionCardex from "./SectionCardex";

function Accueil() {

    const [scroll, setScroll] = useState(true)
    const [myDivs, setMyDivs] = useState([]);
    const [idx, setIdx] = useState(0);
    const [scrollDirection, setScrollDirection] = useState('down')
    const isMobile = useMediaQuery({ query: `(max-width: 811px)` });
    const isScreenOnly = useMediaQuery({query: `(hover: none) and (pointer: coarse)`});
    const {darkMode} = useContext(ThemeContext);

    const onScroll = (e) => {
        setScrollDirection(e.wheelDelta > 0 ? 'up' : 'down')
        if (scroll) {
            setScroll(false)
        }
    }

    useEffect(()=>{
        if(darkMode){
            document.getElementsByTagName('html')[0].classList.add('dark-body');
        }else{
            document.getElementsByTagName('html')[0].classList.remove('dark-body');
        }
    }, [darkMode])


    useLayoutEffect(() => {
        document.getElementsByTagName('footer')[0].classList.remove('footerFixed');
        setMyDivs([document.getElementById('section-banniere').offsetTop, document.getElementById('section-application').offsetTop, document.getElementById('section-testdrive').offsetTop, document.getElementById('section-objectif').offsetTop, document.getElementById('section-cardex').offsetTop,document.getElementById('footer').offsetTop])
        if(!isMobile) document.getElementsByTagName('html')[0].classList.add('overflowHtml');
        else  document.getElementsByTagName('html')[0].classList.remove('overflowHtml');
    }, [])

    const onTouch = () => {
        if(isScreenOnly){
            document.getElementsByTagName('html')[0].classList.remove('overflowHtml');
        }
    }

    useLayoutEffect(()=>{
        document.documentElement.style.scrollBehavior = 'auto';
        setTimeout(() => window.scrollTo(0, 0), 5);
        setTimeout(() => document.documentElement.style.scrollBehavior = 'smooth', 5);
    },[myDivs])

    useEffect(() => {
        if (myDivs.length > 0) {
            window.addEventListener('wheel', onScroll)
         }
        return () => window.removeEventListener('wheel', onScroll)
    }, [myDivs, idx, scroll])

    useEffect(() => {
        if (!scroll) {
            if (scrollDirection === 'down') {
                setIdx(idx === myDivs.length - 1 ? idx : idx + 1);
            } else {
                setIdx(idx === 0 ? idx : idx - 1);
            }
            setTimeout(() => setScroll(true), 1000)
        }
    }, [myDivs, scroll]);

    useEffect(() => {
        window.scrollTo({top: myDivs[idx]})
    }, [idx])

    useEffect(() => {
        const img = document.getElementById('img-section-td');

        if (img) {
            if (idx > 3) {
                img.style = 'z-index: -2 !important;'
            } else {
                img.style = 'z-index: 0 !important;'
            }
        }
    }, [window.pageYOffset])

    return (

            <div id='accueil' onTouchMove={onTouch()} >
                <div id="section-banniere"  />
                <Banniere />
                <div id="section-application" />
                <SectionApplication animation="fade-left"
                                title="L'APPLICATION"
                                vignette={darkMode?ImageDark:Image}/>
                <div id="section-testdrive" style={{visibility: "hidden"}}/>
                <SectionTestDrive animation="fade-left" showImg={idx <= 3}/>
                <div id="section-objectif" />
                <SectionNotreObjectif animation="fade-left"/>
                <div id="section-cardex" />
                <SectionCardex animation="fade-left" showImg={idx >= 3} />
                <div id="footer" />
            </div>
    );
}

export default Accueil;
