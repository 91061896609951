import '../css/Formulaire.css';
import React, {useContext, useLayoutEffect, useState} from "react";
import Image from '../assets/img-form.webp';
import ImgDark from '../assets/img-form-dark.webp';
import plus from "../assets/plus.webp";
import navbarContext from "../context/navbarContext";
import '../css/Textarea.scss';
import FlashMessage from 'react-flash-message';
import Lottie from "lottie-react";
import mailSent from "../assets/mail-sent.json";
import mailError from "../assets/wrong-sent.json";
import {useMediaQuery} from "react-responsive";
import ThemeContext from "../context/ThemeContext";

function Formulaire() {
    const [form, setForm] = useState({mail: '', lastname: '', firstname: '',phonenumber:'', subject: '', content: ''});
    const {hauteurNavbar} = useContext(navbarContext);
    const [messageSent, setMessageSent] = useState(false);
    const [messageError, setError] = useState(false);
    const isMobile = useMediaQuery({query: '(max-width: 430px)'})
    const {darkMode} = useContext(ThemeContext)

    useLayoutEffect(() => {
        document.documentElement.style.scrollBehavior = 'auto';
        setTimeout(() => window.scrollTo(0, 0), 5);
        setTimeout(() => document.documentElement.style.scrollBehavior = 'smooth', 5);
        document.getElementsByTagName('html')[0].classList.remove('overflowHtml');
        if (!isMobile) {
            document.getElementsByTagName('footer')[0].classList.add('footerFixed');
        } else document.getElementsByTagName('footer')[0].classList.remove('footerFixed');

        if(darkMode){
            document.getElementsByTagName('body')[0].classList.add('dark-body');
            document.getElementsByTagName('html')[0].classList.add('dark-body');
        }else{
            document.getElementsByTagName('body')[0].classList.remove('dark-body');
            document.getElementsByTagName('html')[0].classList.remove('dark-body');

        }


    }, [darkMode])
    const Message = () => (

        <FlashMessage duration={3900}>
            <div className="flash-message">
                <div className="div-message">
                    <Lottie animationData={mailSent}/>
                </div>
            </div>
        </FlashMessage>

    )


    const MessageError = () => (

        <FlashMessage duration={2200}>
            <div className="div-message-error" style={{marginTop: hauteurNavbar}}>
                <div className="div-animation">
                    <Lottie animationData={mailError}/>
                </div>
                <p className="message-error">
                    Message non envoyé
                </p>
            </div>
        </FlashMessage>
    )

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const url = "https://api.bopper.fr/mail"
        const mailResponse = await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    mail: form.mail,
                    lastname: form.lastname,
                    firstname: form.firstname,
                    phonenumber:form.phonenumber,
                    subject: form.subject,
                    content: form.content
                })
            }
        )
        if (mailResponse.status === 201) {
            setMessageSent(true);
            setForm({mail: '', lastname: '', firstname: '',phonenumber:'', subject: '', content: ''});
            setTimeout(() => {
                setMessageSent(false);
            }, 3900);
        } else {
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 2200);
        }

    }

    const sendForm = () =>{

        const button = document.getElementsByClassName("send-mobile-form")[0];
        button.click();
    }

    return (
        <div>
            {messageSent && <Message/>}

            {messageError && <MessageError/>}
            <div className={darkMode?"titre-form-dark":"titre-form"} style={{display:isMobile?"flex":'none', top:hauteurNavbar}}>
                <h1 style={{marginRight: "2%"}}>Nouveau contact</h1>
                <button className="btn-form" onClick={handleSubmit}>Envoyer</button>
            </div>
            <div className={darkMode?"div-img-form-dark bg-dark":"div-img-form bg-light"} >
                <img className="img-form"
                     src={darkMode ? ImgDark: Image}
                     alt={"contact"}
                />
            </div>
            <div className={darkMode ? "div-form-dark" : "div-form"}
                 style={{marginTop: !isMobile ? hauteurNavbar : '', marginBottom: !isMobile ? hauteurNavbar : ''}}>


                <form onSubmit={handleSubmit} className="form" style={{display: isMobile ? 'none' : "block"}}>
                    <h1 className={darkMode ? "titre-form-dark" : "titre-form"}>Une question ? Contactez-nous !</h1>
                    <div id="mail" style={{height: '100%'}}>
                        <div className="form__group field">
                            <input type="email"
                                   id="mail_mail"
                                   name="mail"
                                   required="required"
                                   className={darkMode?"form__field_dark":"form__field"}
                                   value={form.mail}
                                   placeholder="Adresse mail"
                                   onChange={handleChange}
                            />
                            <label htmlFor="mail_mail"
                                   className={darkMode ? "required form__label_dark" : "required form__label"}>Adresse
                                mail</label>
                        </div>
                        <div className="form__group field">
                            <input type="text"
                                   id="mail_lastname"
                                   name="lastname"
                                   required="required"
                                   className={darkMode?"form__field_dark":"form__field"}
                                   value={form.lastname}
                                   placeholder="Nom"
                                   onChange={handleChange}
                            />
                            <label htmlFor="mail_lastname" className={darkMode ? "required form__label_dark" : "required form__label"}>Nom</label>
                        </div>
                        <div className="form__group field">
                            <input type="text"
                                   id="mail_firstname"
                                   name="firstname"
                                   required="required"
                                   className={darkMode?"form__field_dark":"form__field"}
                                   value={form.firstname}
                                   placeholder="Prénom"
                                   onChange={handleChange}
                            />
                            <label htmlFor="mail_firstname" className={darkMode ? "required form__label_dark" : "required form__label"}>Prénom</label>
                        </div>
                        <div className="form__group field">
                            <input type="text"
                                   id="mail_subject"
                                   name="subject"
                                   required="required"
                                   className={darkMode?"form__field_dark":"form__field"}
                                   value={form.subject}
                                   placeholder="Sujet"
                                   onChange={handleChange}
                            />
                            <label htmlFor="mail_subject" className={darkMode ? "required form__label_dark" : "required form__label"}>Sujet</label>
                        </div>
                        <div className="form__group field">
                            <textarea
                                id="mail_content"
                                name="content"
                                required="required"
                                className={darkMode?"form__field_dark textarea-form ":"form__field textarea-form"}
                                value={form.content}
                                placeholder="Mon message"
                                onChange={handleChange}/>
                            <label htmlFor="mail_content" className={darkMode ? "required form__label_dark" : "required form__label"}>Mon message</label>
                        </div>
                        <div>
                            <button type="submit" id="mail_save" name="mail[save]"
                                    className="btn btn-form form_button">Envoyer
                            </button>
                        </div>
                        <input type="hidden" id="mail__token" name="mail[_token]"
                               value="a97c79a2.Z8gZdLgin9p-HuQr92spRxUntt_w_VdjHPFuWU1C7ko.D5ApJIBN8ZkLKZx_gllBH1QX3qy8lzURL8EFLCckqiYTi08ZiFDT6hZN0A"/>
                    </div>
                </form>

                <form className={darkMode?"bg-dark":"bg-light"} onSubmit={handleSubmit} style={{display: isMobile ? 'block' : "none", background:darkMode?"#212529":"transparent"}}>
                    <div className={darkMode?"mobile-form-dark":"mobile-form"}>

                        <div id="mail" style={{height: '100%'}}>
                            <input type="email"
                                   name="mail"
                                   required="required"
                                   className={darkMode?"mobile-form-input-dark":"mobile-form-input"}
                                   value={form.mail}
                                   placeholder="Adresse mail"
                                   onChange={handleChange}
                            />
                            <hr/>
                            <input type="text"
                                   name="lastname"
                                   required="required"
                                   className={darkMode?"mobile-form-input-dark":"mobile-form-input"}
                                   value={form.lastname}
                                   placeholder="Nom"
                                   onChange={handleChange}
                            />    <hr/>

                            <input type="text"
                                   name="firstname"
                                   required="required"
                                   className={darkMode?"mobile-form-input-dark":"mobile-form-input"}
                                   value={form.firstname}
                                   placeholder="Prénom"
                                   onChange={handleChange}
                            />
                        </div>
                    </div>
                    <br/>
                    <div className={darkMode?"mobile-form-dark":"mobile-form"} style={{display:"flex"}}>
                        <div style={{display:"flex", flexDirection:"row", alignItems:"center", width:"100%"}}>
                            <img style={{marginLeft:"2%",width:"5%"}} src={plus} alt="plus" />
                            <div id="mail" style={{height: '100%', width:"100%"}}>
                                <input type="number"
                                       name="phonenumber"
                                       style={{width:"100%"}}
                                       className={darkMode?"mobile-form-input-dark":"mobile-form-input"}
                                       value={form.phonenumber}
                                       placeholder="ajouter un numéro (otionnel)"
                                       onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className={darkMode?"mobile-form-dark":"mobile-form"}>

                        <input type="text"
                               name="subject"
                               required="required"
                               className={darkMode?"mobile-form-input-dark":"mobile-form-input"}
                               value={form.subject}
                               placeholder="Sujet"
                               onChange={handleChange}
                        /> <hr/>
                        <textarea
                            name="content"
                            required="required"
                            className={darkMode?"mobile-form-input-dark textarea-form":"mobile-form-input textarea-form"}
                            style={{width:"90%"}}
                            value={form.content}
                            placeholder="Mon message"
                            onChange={handleChange}/>
                        <div>
                        </div>
                        <button type="submit" id="mail_save" name="mail[save]" style={{display:"none"}}  className={"send-mobile-form"}>
                        </button>
                    </div>
                    <input type="hidden" id="mail__token" name="mail[_token]"
                           value="a97c79a2.Z8gZdLgin9p-HuQr92spRxUntt_w_VdjHPFuWU1C7ko.D5ApJIBN8ZkLKZx_gllBH1QX3qy8lzURL8EFLCckqiYTi08ZiFDT6hZN0A"/>
                </form>
            </div>
        </div>
    )
        ;
}

export default Formulaire;

