import '../css/Section.css'
import navbarContext from "../context/navbarContext";
import listeDark from '../assets/bopper-liste-dark.webp'
import liste from '../assets/bopper-liste.webp';
import {useContext, useEffect} from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import React from "react";
import {useMediaQuery} from "react-responsive";
import ThemeContext from "../context/ThemeContext";

function SectionApplication(props) {
    const {hauteurNavbar} = useContext(navbarContext);
    const isMobile = useMediaQuery({query: `(max-width: 961px)`});
    const {darkMode} = useContext(ThemeContext);

    useEffect(() => {
        AOS.init({duration: 1000, once: true});
    }, [])

    return (
        <section className={!darkMode ? "toutes-sections section" : "toutes-sections section-dark"} id="presentation"
                 style={{paddingTop: hauteurNavbar}}>
            <h1 data-aos={props.animation} className="titre-section"
                style={{marginTop: hauteurNavbar, display: !isMobile && 'none'}}> {props.title}</h1>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                width: !isMobile && "50%",
                marginTop: hauteurNavbar,
                height: isMobile && "60%"
            }}>
                <img data-aos={props.animation} className="img-iphone-1" src={props.vignette}
                     alt={"Design de la page Profil de l'appli"}/>
                <img data-aos={props.animation} className="img-iphone-2" src={darkMode ? listeDark : liste}
                     alt={"Design de la page d'accueil de l'appli"} style={{
                    position: "relative",
                    right: "0",
                    marginLeft: "35%"
                }}/>
            </div>
            <div data-aos={props.animation} className="col col-section">
                <h1 data-aos={props.animation} className="titre-section"
                    style={{paddingTop: hauteurNavbar, display: isMobile && 'none'}}> {props.title}</h1>

                <div data-aos={props.animation} className="col-sm description">
                    <div data-aos={props.animation}>
                        BOPPER est une application innovante qui réunit l’ensemble des acteurs du monde de l’automobile (particulier, professionnel, association). Vous disposerez de fonctionnalités et de services spécialement conçus afin de créer un univers unique, qui comblera vos besoins mais également, qui vous fera rêver.
                        <br/><br/>
                        Notre objectif est de proposer des nouveaux concepts qui propulseront votre expérience dans une autre dimension. Vous retrouverez un réseau social adapté à la passion automobile, la possibilité de réserver un essai d’un véhicule autour de soi en “quelques clics” mais également, de devenir un CarSpotter hors pair en collectionnant les véhicules dans son CarDex. Bien sûr, nous n’en resterons pas là puisque d’autres fonctionnalités arrivent prochainement !
                        <br/><br/>
                        Nous resterons fidèles à nos valeurs qui sont <b>le partage, le plaisir et l'accessibilité.</b> Nous avons mis en place un système de certification qui permet à nos utilisateurs de s’exprimer librement et de développer leur passion en créant des liens réels avec d'autres passionnés.
                    </div>

                </div>
            </div>
        </section>
    );
}

export default SectionApplication;
