import React from "react";
import notFound from '../assets/404.json';
import Lottie from "lottie-react";
import '../css/NotFound.css'

function NotFound() {
    return (
        <div className="lottie404">

            <Lottie style={{background: "rgba(255,255,255,.5", height: "50%", borderRadius: "20px"}} autoplay={true}
                    loop={true} animationData={notFound}/>

        </div>
    );
}

export default NotFound;