import '../css/FAQ.css'
import navbarContext from "../context/navbarContext";
import React, {useContext, useLayoutEffect, useState} from "react";
import {useMediaQuery} from "react-responsive";
import ThemeContext from "../context/ThemeContext";

function FAQ() {
    const isMobile = useMediaQuery({query: `(max-width: 430px)`})
    const isReduced = useMediaQuery({query: '(max-width: 960px) and (min-width: 810px)'})
    const [form, setForm] = useState({recherche: ''});
    const {hauteurNavbar} = useContext(navbarContext);
    const [questions, setQuestions] = useState([]);
    const {darkMode} = useContext(ThemeContext)

    useLayoutEffect(() => {
        document.getElementsByTagName('html')[0].classList.remove('overflowHtml');
        document.getElementsByTagName('footer')[0].classList.remove('footerFixed');

        if(darkMode){
            document.getElementsByTagName('body')[0].classList.add('dark-body');
            document.getElementsByTagName('html')[0].classList.add('dark-body');
        }else{
            document.getElementsByTagName('body')[0].classList.remove('dark-body');
            document.getElementsByTagName('html')[0].classList.remove('dark-body');

        }

    }, [darkMode])
    useLayoutEffect(() => {
        document.documentElement.style.scrollBehavior = 'auto';
        setTimeout(() => window.scrollTo(0, 0), 5);
        setTimeout(() => document.documentElement.style.scrollBehavior = 'smooth', 5);
    })

    useLayoutEffect(() => {
        const initQuestion = [...document.getElementsByClassName('question')];
        let i = 1;
        initQuestion.forEach(elements => {
            elements.id = i;
            i++;
        })
    }, []);


    const handleChange = (event) => {
        const value = event.target.value;
        setForm({recherche: value});
        setQuestions([...document.getElementsByClassName('question')]);
        if (value.length === 0) {
            setQuestions([]);
        }
    }
    return (
        <div>
            <div className={!darkMode ? "faq" : "faq-dark"}>
                <div className="en-tete" style={{marginTop: hauteurNavbar}}>
                    <h1>FOIRE AUX QUESTIONS</h1><h2 style={{fontWeight: "lighter"}}>TOUTES LES RÉPONSES À VOS
                    QUESTIONS</h2>
                    <h3 className={"titre-recherche"}>Vous cherchez quelque chose de particulier ?</h3>
                    <div className="champ-recherche">
                        <div className="form__group field"
                             style={{width: isMobile ? "100%" : isReduced ? "60%" : "40%"}}>
                            <input type="text"
                                   id="faq_recherche"
                                   name="recherche"
                                   required="required"
                                   className="form__field"
                                   value={form.recherche}
                                   placeholder="Effectuez une recherche dans notre FAQ"
                                   onChange={handleChange}
                            />
                            <label htmlFor="faq_recherche" className="required form__label"
                                   style={{fontSize: isMobile && "medium"}}>Poser une question ici</label>
                        </div>
                        <div className="questions-trouvees">
                            {questions.filter(question => question.innerText.toLowerCase().includes(form.recherche.toLowerCase())).map(filteredQuestion => (
                                <li className={!darkMode ? "liste-questions" : "liste-questions-dark"}>
                                    <a href={"#" + filteredQuestion.id}
                                       style={{width: isMobile ? "100%" : "40%"}}>{filteredQuestion.innerText}</a>
                                </li>
                            ))}
                        </div>

                    </div>
                    <div className="div2">
                        <div className="menu-card">
                            <div className="ligne">
                                <a id="carteTestDrive" className={!darkMode ? "carte" : "carte-dark"} href="#profil">
                                    <p>LE PROFIL</p></a>
                                <a id="carteBopperDrive" className={!darkMode ? "carte" : "carte-dark"}
                                   href="#reseauSocial">
                                    <p>RESEAU SOCIAL</p></a>
                                <a id="carteTestDrive" className={!darkMode ? "carte" : "carte-dark"} href="#testDrive">
                                    <p>TEST DRIVE</p></a>
                            </div>
                            <div className="ligne">
                                <a id="carteAssurance" className={!darkMode ? "carte" : "carte-dark"} href="#cardex">
                                    <p>CARDEX</p>
                                </a>
                                <a id="carteCommission" className={!darkMode ? "carte" : "carte-dark"} href="#bopperPro">
                                    <p>BOPPER PRO</p></a>
                                <a id="carteBopper" className={!darkMode ? "carte" : "carte-dark"} href="#apropos">
                                    <p>A PROPOS</p>
                                </a>
                            </div>
                        </div>

                        <div className="corpsFaq">
                            <h1 id="profil" className="categorie">LE PROFIL</h1>
                            <h2 className="question">Qu'est-ce qu'un profil sur Bopper ?</h2>
                            <p className="reponse">Le profil regroupe l’ensemble des informations et contenu de la personne physique
                                ou la personne morale, à savoir :
                                <ul>
                                    <li>Son pseudo</li>
                                    <li>Sa description</li>
                                    <li>Son niveau d'expérience sur l'application</li>
                                    <li>Ses publications</li>
                                    <li>Son garage</li>
                                </ul>
                            </p>

                            <p className="reponse">Vous pouvez accéder à votre profil en cliquant en bas à droite de l'écran sur l’icône
                                “Bonhomme” et ainsi :
                                <ul>
                                    <li>Publier du contenu en cliquant sur le bouton bleu +</li>
                                    <li>Gérer votre garage en indiquant les véhicules que vous possédez ou que vous avez possédé,
                                        mais également proposer vos véhicules actuels en Test Drive. </li>
                                </ul>
                            </p>

                            <p className="reponse">Vous retrouvez également vos notifications en haut à droite de votre profil, en cliquant
                                sur la “cloche”. </p>

                            <br/>
                            <h2 className="question">A quoi sert la certification de compte ?</h2>
                            <p className="reponse">La certification du compte est totalement gratuite et te permet de proposer tes véhicules
                                en Test Drive mais également de réserver ceux disponibles sur l’application. Également, elle nous affirme la
                                véracité sur l'identité du profil.
                            </p>

                            <p className="reponse">Notre modèle d’application repose sur des échanges de contenu automobile, de mise en relation
                                pour tester des véhicules ainsi que plusieurs autres fonctionnalités, et ces vérifications apportent un gage de
                                sécurité pour l’ensemble de nos utilisateurs.
                            </p>

                            <p className="reponse">Les insultes, harcèlements, provocations ainsi que plusieurs autres délits sont devenus trop
                                banales de nos jours, et nous souhaitons maitriser ces phénomènes sur BOPPER à travers la certification. Nous voulons
                                que chaque personne utilisant notre application soit responsable de ses propos et de ses actes. Bien sûr, notre équipe
                                reste vigilante sur l’ensemble du contenu de l’application ainsi que les signalements de la communauté.
                            </p>

                            <p className="reponse">De plus, avec l’évolution de l’application, la certification permettra d’accéder à du contenu exclusif
                                par la suite, mais nous gardons le secret pour l’instant.
                            </p>

                            <p className="reponse">Pour finir, <b>les données sont traitées de manière sécurisée par MANGOPAY</b>, ce qui veut dire que nous
                                n’avons aucun accès à ces documents, ni aux informations qu’ils contiennent.
                            </p>

                            <br/>
                            <h2 className="question">Comment se certifier ?</h2>
                            <p className="reponse">C’est très simple ! Sur la page de ton profil, clique sur le "bouton orange médaille”. Ensuite, il te reste juste à
                                indiquer ta nationalité, ton pays de résidence et transmettre le document de ton choix.
                            </p>

                            <p className="reponse">Il faut compter quelques heures le temps que la certification s’effectue. Dès que celle-ci est validée, tu recevras une
                                notification et à toi l’univers BOPPER !
                            </p>

                            <br/>
                            <h2 className="question">Quels sont les documents pour certifier un compte particulier ?</h2>
                            <p className="reponse">Il existe deux niveaux de certification qui dépend uniquement de ton utilisation de l’application :
                                <ul>
                                    <li>Si tu souhaites réserver des véhicules en Test Drive, il suffit de transmettre un justificatif d’identité :
                                    <ul>
                                        <li>la Carte d’identité</li>
                                        <li>le passeport</li>
                                        <li>ou le permis de conduire</li>
                                    </ul>
                                    </li>
                                    <li>Si tu souhaites proposer ton véhicule en Test Drive, il faut transmettre un justificatif d’identité ainsi
                                        qu’un RIB sur lequel tu souhaites que l’on verse ta commission.</li>
                                </ul>
                            </p>

                            <br/>
                            <h2 className="question">Quels sont les documents pour certifier un compte professionnel ?</h2>
                            <p className="reponse">Afin de certifier un compte professionnel, il faut transmettre via votre profil :
                                <ul>
                                    <li>Justificatif d'identité du dirigeant</li>
                                    <li>KBIS de la société</li>
                                    <li>Statuts de la société</li>
                                    <li>UBO</li>
                                    <li>IBAN</li>
                                </ul>
                            </p>

                            <br/>
                            <h2 className="question">A quoi sert le garage ?</h2>
                            <p className="reponse">Le garage te permet de gérer l’ensemble des véhicules que tu as eu ou que tu as actuellement.
                                En les renseignant, tu peux ensuite rattacher tes publications à ton véhicule pour avoir un dossier complet de
                                tes différents bolides !
                            </p>

                            <p className="reponse">Il sert également de tableau de bord puisque tu retrouves le nombre de “Bops” que ton véhicule
                                a reçu, ses caractéristiques mais aussi de le proposer en essai.
                            </p>

                            <br/>
                            <h2 className="question">C'est quoi le niveau d'expérience</h2>
                            <p className="reponse">Vous retrouvez sur votre profil un niveau d’expérience qui fonctionne avec des XPs. Ces XPs se
                                gagnent en publiant, en se connectant sur l’application, en proposant son véhicule en Test Drive, en intégrant de
                                nouveau véhicule dans le CarDex. Il existe une multitude de paramètre et cela accroit votre niveau. De plus, et
                                selon votre activité sur l’application, un badge vous sera attribué par nos soins, comme “Paparazzi de Porsche” ou
                                bien “As du volant”, et il peut évoluer dans le temps. A toi de jouer maintenant sur BOPPER pour obtenir le badge
                                qui te correspondra le mieux.
                            </p>





                            <h1 id="reseauSocial" className="categorie">RESEAU SOCIAL</h1>
                            <h2 className="question">L'avantage du réseau social</h2>
                            <p className="reponse">Notre réseau social sert à créer un lien avec toute la communauté, que ce soit pour poster du contenu
                                automobile, un évènement ou bien des informations. Chacun est libre de “Bopper” ou de commenter.
                            </p>

                            <p className="reponse">Nous développons actuellement, les “Crews” qui permettront de personnaliser vos relations et créer
                                des groupes privés.
                            </p>

                            <br/>
                            <h2 className="question">Comment poster du contenu ?</h2>
                            <p className="reponse">Depuis ton profil ou le fil d’actualité, clique sur le “bouton bleu +”. Choisis s’il s’agit d’une publication,
                                ou s'il s'agit d'une photo à intégrer dans ton Cardex, et laisse-toi guider ensuite. </p>

                            <br/>
                            <h2 className="question">Comment supprimer une publication ?</h2>
                            <p className="reponse">Il suffit de cliquer sur la publication que tu souhaites supprimer, clique ensuite sur les 3 points à côté
                                de la photo, puis “supprimer”. </p>

                            <br/>
                            <h2 className="question">C’est quoi un BOP ?</h2>
                            <p className="reponse">Un BOP est un j’aime, tout simplement !</p>

                            <br/>
                            <h1 id="testDrive" className="categorie">TEST DRIVE</h1>
                            <h2 className="question">Comment proposer son un véhicule en Test Drive ?</h2>
                            <p className="reponse">Depuis ton profil sur l’onglet garage, choisis le véhicule que tu souhaites
                                mettre en Test Drive. Ensuite clique sur l’icône “clé” à droite. Il te reste à paramétrer les
                                conditions ainsi que tes disponibilités.</p>

                            <br/>
                            <h2 className="question">Le Test Drive est-il payant ?</h2>
                            <p className="reponse">Nous avons réuni plusieurs acteurs du monde de l’automobile. Chacun est libre
                                de fixer un montant ou d’indiquer la gratuité pour essayer le véhicule. Dans la majorité des cas,
                                les Test Drive seront payant puisqu’ils permettent de compenser les frais liés à l’essai (carburant,
                                kilométrage, etc.), le temps consacré mais aussi le fait de rendre accessible certains véhicules prestigieux.
                            </p>

                            <br/>
                            <h2 className="question">Les avantages de proposer ton véhicule en Test Drive sur BOPPER</h2>
                            <p className="reponse">
                                <ul>
                                    <li>Procurer un moment de partage et d’échange.</li>
                                    <li>Mettre en avant ton profil et augmenter ta visibilité.</li>
                                    <li>Percevoir une commission financière à la hauteur de vos attentes.</li>
                                    <li>Liberté d’accepter ou refuser le Test Drive.</li>
                                    <li>Tous les utilisateurs de l’application voient votre véhicule. </li>
                                    <li>Garder le choix de proposer le Test Drive soit passager et/ou conducteur.</li>
                                </ul>
                            </p>

                            <br/>
                            <h2 className="question">Les avantages de réserver un véhicule en Test Drive sur BOPPER</h2>
                            <p className="reponse">
                                <ul>
                                    <li>Prendre du plaisir soit au volant, soit en tant que passager.</li>
                                    <li>Se faire une idée du véhicule en cas d’achat futur.</li>
                                    <li>Réservation du véhicule selon ton choix en quelques clics.</li>
                                    <li>Véhicule disponible dans toute la France.</li>
                                    <li>Aucune démarche administrative.</li>
                                    <li>Prix moins élevés qu’une location</li>
                                </ul>
                            </p>

                            <br/>
                            <h2 className="question">Chartre de la bonne conduite lors d’un Test Drive</h2>
                            <p className="reponse">
                                <ul>
                                    <li>Être fiable. Je serai à l’heure et à l’endroit du rendez-vous. En cas de retard,
                                        je préviens au plus vite la personne.</li>
                                    <li>Respect du code de la route pour veiller à la sécurité des autres membres et à la mienne.</li>
                                    <li>Être accueillant et sociable. Soyez disponible et sensible aux préférences de conduite des membres.</li>
                                    <li>Laisser un avis honnête, juste et poli envers les autres membres.</li>
                                    <li>Aucune démarche administrative.</li>
                                    <li>Et surtout, profitez de ce moment de partage</li>
                                </ul>
                            </p>

                            <br/>
                            <h2 className="question">Comment choisir un point de rencontre ?</h2>
                            <p className="reponse">Si vous êtes un particulier : Eviter de donner rendez-vous à votre domicile ou sur
                                votre lieu de travail.
                            </p>
                            <p className="reponse">Nous allons prochainement sur l’application référencer des aires, lieux précis et
                                parkings propices avec des itinéraires définis pour un maximum de plaisir tout en sécurité.
                            </p>

                            <br/>
                            <h2 className="question">Quelle est la durée d'un Test Drive ?</h2>
                            <p className="reponse">Le propriétaire ou le professionnel est libre de définir la durée du Test Drive et
                                celle-ci doit être fixée à l’avance.
                            </p>
                            <p className="reponse">Il s’agit très souvent d’une durée qui varie entre 20 minutes à1h sur route.
                                Cela va dépendre des caractéristiques du véhicule (puissance, rareté, finition).
                            </p>

                            <br/>
                            <h2 className="question">Comment réserver l’essai d’un véhicule ?</h2>
                            <p className="reponse">Il suffit de cliquer sur la rubrique “Test Drive” en bas de l’application,
                                et selon ta préférence, tu peux soit choisir ton véhicule depuis la liste avec les véhicules
                                les plus proches de soi, soit depuis la carte interactive représentant toute la France. Clique
                                sur le véhicule de ton choix pour ensuite te laisser guider jusqu’à la fin de réservation.
                            </p>

                            <br/>
                            <h2 className="question">A quel moment mon Test Drive est-il validé ?</h2>
                            <p className="reponse">Lorsque le propriétaire/professionnel aura validé votre demande, vous recevrez
                                un mail ainsi qu’une notification. Ainsi, votre Test Drive est prêt et programmé. Vous retrouvez
                                votre réservation en cliquant sur l’icône “Calendrier” en bas de l’application.
                            </p>

                            <br/>
                            <h2 className="question">Pourquoi n’ai-je pas reçu l’email de confirmation ?</h2>
                            <p className="reponse">Si vous n'avez pas reçu un e-mail de confirmation dans les 10 minutes qui suivent
                                votre réservation, vérifiez les différentes boites de votre messagerie (notamment si vous utilisez Gmail) :
                                spam / courriers indésirables.
                            </p>

                            <br/>
                            <h2 className="question">Comment modifier ma réservation Test Drive ?</h2>
                            <p className="reponse">Pour modifier une réservation de Test Drive, il faut aller dans l'onglet Reservations,
                                selectionner le Test Drive en question et vous aurez un bouton "Annuler" en bas de la page qui vous permettra
                                d'annuler la reservation.
                            </p>

                            <br/>
                            <h2 className="question">Quelles sont les conditions d’annulation et de remboursement ?</h2><br/>
                            <p className="reponse">

                                <b>PROPRIETAIRE</b>
                                <br/><br/>
                                <b>Je n'ai pas donné de réponse à un Test Drive dans les 5 jours après sa date de demande</b>
                                <br/>
                                    Le Test Drive passe en mode "Pas de réponse" et vous ne pouvez plus y répondre.

                                <br/><br/>
                                <b>J'ai déjà validé le Test Drive mais je décide finalement de l'annuler</b>
                                <br/>
                                Vous avez une dette correspondant aux frais de service. Cette dette sera rattrapé lors du prochain
                                Test Drive que vous validerez sur un de vos véhicules. Vous récupérerez le prix du Test Drive moins la dette.

                                <br/><br/>
                                <b>je refuse une demande de Test Drive</b>
                                <br/>
                                Le Test Drive passe en mode « Refusé » et vous ne pouvez plus y répondre.

                                <br/><br/>
                                <b>Le demandeur annule le Test Drive que vous avez déjà accepté plus de 72 heures avant l'heure du Test Drive</b>
                                <br/>
                                Vous percevez 50% du montant du Test Drive.

                                <br/><br/>
                                <b>Le demandeur annule le Test Drive que vous avez déjà accepté moins de 24 heures avant l'heure du Test Drive</b>
                                <br/>
                                Vous percevez 100% du montant du Test Drive.

                                <br/><br/>
                                <b>DEMANDEUR</b>

                                <br/><br/>
                                <b>J'annule ma demande avant que le propriétaire ne donne sa réponse</b>
                                <br/>
                                Le Test Drive passe en mode « Annulé » et n'est plus accessible. Vous ne serez pas débité et vous n'aurez donc rien payé.

                                <br/><br/>
                                <b>J'annule un Test Drive déjà accepté par le propriétaire plus de 72 heures avant l'heure du Test Drive</b>
                                <br/>
                                Vous êtes remboursé du Test Drive mais pas des frais de services BOPPER.

                                <br/><br/>
                                <b>J'annule un Test Drive déjà accepté par le propriétaire entre 24 et 72 heures avant l'heure du Test Drive</b>
                                <br/>
                                Vous êtes remboursé de 50% du prix du Test Drive. Les frais de services de BOPPER ne sont pas remboursés.

                                <br/><br/>
                                <b>J'annule un Test Drive déjà accepté par le propriétaire moins de 24 heures avant l'heure du Test Drive</b>
                                <br/>
                                Vous n'êtes remboursé ni du Test Drive, ni des frais de services de BOPPER.
                            </p>

                            <br/>
                            <h2 className="question">Quand vais-je recevoir mon virement ?</h2>
                            <p className="reponse">Le virement sera exécuté sous un délai de 2 à 3 jours après le Test Drive sur votre compte
                                bancaire fourni lors de la certification. </p>

                            <br/>
                            <h2 className="question">Comment fixer le prix de mon test drive ?</h2>
                            <p className="reponse">Le prix est libre. Il va dépendre de plusieurs paramètres tel que :
                            <ul>
                                <li>Test Drive en tant que conducteur ou passager</li>
                                <li>Puissance du véhicule</li>
                                <li>Notoriété du véhicule</li>
                                <li>Rareté du véhicule</li>
                            </ul>
                            </p>

                            <br/>
                            <h2 className="question">A combien s’élève notre commission BOPPER ?</h2>
                            <p className="reponse">Nous avons fixé une commission forfaitaire de 4€ sur chaque Test Drive qui permet de
                                couvrir les différents frais de l’application (certification et gestion des paiements auprès de MangoPay ,
                                développement), et cela, peu importe le montant, la durée ou la localisation du Test Drive. </p>

                            <p className="reponse">Nous sommes des passionnés automobiles, notre objectif premier est de proposer une application
                                dédiée à la communauté automobile. Nous intégrons le système de Test Drive et nous souhaitons nous occuper des détails
                                superflus. Cela passe par la certification des comptes des utilisateurs qui est un gage de sécurité, la gestion des
                                paiements entre les deux utilisateurs, mais aussi le développement de l’application.</p>

                            <br/>
                            <h2 className="question">Qu'en est-il de l'assurance ?</h2>
                            <p className="reponse">Essai d’un véhicule entre particulier :
                            <ul>
                                <li>
                                    Si le Test Drive s’effectue en tant que passager, rien ne change. Laissez-vous guider par le propriétaire qui
                                    saura vous faire découvrir l’étendue du véhicule.
                                </li>
                                <li>
                                    Si le Test Drive s’effectue en tant que conducteur, nous conseillons au propriétaire d'être en
                                    adéquation avec son contrat automobile, l’appellation peut être différente selon l’assurance comme :
                                    « prêt de volant ». Nous travaillons pour mettre en place une assurance qui couvrira l’ensemble des
                                    Test Drive en tant que conducteur.
                                </li>
                            </ul>
                                Essai d'un véhicule auprès d'un professionnel :
                                <ul>
                                    <li>Allez-y les yeux fermés.</li>
                                </ul>
                            </p>

                            <br/>
                            <h1 id="cardex" className="categorie">CARDEX</h1>
                            <h2 className="question">En quoi consiste le CarDex ?</h2>
                            <p className="reponse">Le CarDex, signifiant « Encyclopédie de voiture » est un objet technologique fictif de l'univers
                                Bopper : il s'agit d'une encyclopédie recensant les véhicules du monde entier. Il permet d'enregistrer les informations
                                sur les véhicules. Dans l’application Bopper, le but du joueur est de compléter le CarDex en capturant l'ensemble des véhicules
                                réparties dans le monde.  </p>

                            <p className="reponse">Reçu au début de l'aventure, il permet d'identifier les véhicules capturés. Le joueur peut aussi voir quel véhicule
                                n’est pas en sa possession. Les véhicules capturés portent le logo de leur marque dans la liste à côté de leur nom et permettent de
                                débloquer de nombreux avantages sur Bopper.  </p>

                            <br/>
                            <h2 className="question">Comment remplir mon CarDex ?</h2>
                            <p className="reponse">
                                <ul>
                                    <li>Ouvrez votre application préféré BOPPER.</li>
                                    <li>Cliquez sur le bouton + en bas à droite de votre écran.</li>
                                    <li>Sélectionnez "CarDex"</li>
                                    <li>Ajoutez votre photo et confirmez que vous avez lu et accepter les conditions.</li>
                                    <li>Cliquez sur "Suivant"</li>
                                    <li>Selectionnez la marque, le modèle, la génération et la série (tout le reste est optionnel).</li>
                                    <li>Cliquez sur "Terminer"</li>
                                </ul>
                            </p>

                            <p className="reponse">
                                Voilà votre premier véhicule est ajouté à votre CarDex ! Vous pouvez le voir depuis l’accueil dans l’onglet CarDex ou dans votre
                                CarDex en cliquant sur l’appareil photo dans la barre de navigation. Bonne chasse !
                            </p>

                            <br/>
                            <h2 className="question">Et si je ne connais pas le modèle ?</h2>
                            <p className="reponse">Pas de panique, il existe de nombreuses applications ou site internet permettant d’avoir les informations
                                d’un véhicule en écrivant simplement sa plaque d’immatriculation comme : https://siv-auto.fr
                            </p>
                            <p className="reponse"> Sinon profitez-en pour faire le tour du véhicule et trouver des indices ou simplement en échangeant avec le
                                propriétaire. Nous ajouterons une fonctionnalité plus tard permettant à la communauté d’aider les autres. </p>

                            <br/>
                            <h2 className="question">Et si je me trompe dans l’ajout d’un véhicule ? </h2>
                            <p className="reponse">Il est possible de supprimer un véhicule de votre car-dex, cela entraine la perte de votre XP et vos potentiels titre
                                le concernant. Pour ce faire il vous suffit de rester appuyé sur votre post de véhicule jusqu’à voir l’onglet "supprimer".
                            </p>

                            <br/>
                            <h2 className="question">En quoi consiste le carspotting ?</h2>
                            <p className="reponse">Le car spotting (littéralement : « observation de voiture ») est une activité consistant à photographier des automobiles
                                intéressantes, qu'elles soient rares, de luxe ou anciennes dans l'espace public. On appelle celui qui la pratique un « car spotter ». Le but est de
                                photographier un grand nombre de voitures de luxe telles que celles dites « supercars ». (https://fr.wikipedia.org/wiki/Car_spotting)
                            </p>

                            <br/>
                            <h2 className="question">Les règles de bienveillances du carpsotting?</h2>
                            <p className="reponse">
                                <ul>
                                    <li>Pour un meilleur rendu, prenez vos photos en mode portrait.</li>
                                    <li>Ne traversez pas la route en regardant à travers votre téléphone.</li>
                                    <li>Ne pénétrez pas les lieux privés sans accord préalable</li>
                                    <li>Ne dégradez pas les véhicules que vous spottez.</li>
                                    <li>N'importez pas de photos d'internet, utilisez uniquement vos photos personnelles.</li>
                                    <li>Assurez-vous que votre photo corresponde bien au modèle choisi.</li>
                                    <li>N'hésitez pas à corriger vos photos afin d'améliorer les éclairages et contrastes.</li>
                                    <li>Demandez l'accord de toutes les personnes sur vos photos avant de les publier.</li>
                                    <li>Soyez respectueux et non intrusifs.</li>
                                    <li>Obtenez l'accord du propriétaire ou floutez la plaque d'immatriculation.</li>
                                </ul>
                            </p>

                            <h1 id="bopperPro" className="categorie">BOPPER PRO</h1>
                            <h2 className="question">BOPPER pour les professionnels aussi ?</h2>
                            <p className="reponse">Toutes les personnes morales sont les bienvenues sur notre application. Avec BOPPER, vous pourrez
                                augmenter votre visibilité en étant au plus proche de notre communauté. Partagez des véhicules, organisez des
                                évènements et faîtes découvrir vos véhicules ! </p>

                            <p className="reponse">Bien entendu, nous restons à votre disposition pour vous accompagner dans vos démarches mais
                                aussi répondre à toutes vos questions. </p>

                            <br/>
                            <h2 className="question">Concessionnaire</h2>
                            <p className="reponse">Proposez vos véhicules d’essai sur notre application. Un acheteur potentiel qui souhaite comparer plusieurs modèles
                                ou alors valider un achat pourra ainsi réserver son essai en quelques clics à travers BOPPER. La communauté aura une visibilité rapide
                                de votre concession, une idée exacte des modèles proposés.  </p>

                            <p className="reponse">En conclusion, une visibilité augmentée de votre concession et des rendez-vous rapide pour la communauté. Pour vous, une
                                gestion des essais simplifié et un futur client déjà informé par les modèles proposés. </p>

                            <br/>
                            <h2 className="question">Loueur de véhicule</h2>
                            <p className="reponse">Vous possédez une flotte de véhicule, et certaines ne sont pas loués. Nous sommes convaincus que les faire essayer à notre
                                communauté permettra de combler votre agenda tout en faisant rêver un membre. </p>

                            <br/>
                            <h2 className="question">Association</h2>
                            <p className="reponse">Vous souhaitez créer un évènement et organiser des sessions de Test Drive dans le cadre d’une promotion, d’une œuvre caritative
                                ou alors d’un rassemblement automobile ? L’application vous simplifiera la tâche en gérant votre agenda à votre place ainsi que la gestion des paiements. </p>

                            <h1 id="apropos" className="categorie">A PROPOS</h1>
                            <h2 className="question">Principes fondamentaux</h2>
                            <p className="reponse">BOPPER ne vous demandera jamais de lui communiquer vos identifiants de connexion ni vos
                                informations de paiement en dehors de ses plateformes. Si vous pensez avoir identifié un lien suspect reçu
                                par message ou par e-mail vous demandant ce type d’informations, signalez-le Immédiatement.  </p>

                            <h2 className="question">Une communauté de confiance</h2>
                            <p className="reponse">Les profils, photos, avis et publications font objet d’une modération pour garantir confiance
                                et respect. </p>

                            <h2 className="question">Quelles sont les règles à connaître concernant la messagerie ?</h2>
                            <p className="reponse">La messagerie vous permet d’organiser les détails des Test Drive mais également d’échanger
                                avec la communauté. Vous pouvez également contacter notre équipe, via notre Community Manager pour toutes questions.
                                Nous pouvons modérer des messages pour garantir un espace convivial où chacun(e) se sent en sécurité. </p>

                            <p className="reponse">Ne divulguez jamais vos informations personnelles (exemples : carte bancaire, coordonnées financières,
                                adresse mail, etc.) avant d’avoir réservé votre essai depuis notre plateforme.</p>

                            <h2 className="question">Comment faire une suggestion ?</h2>
                            <p className="reponse">Vous avez une idée pour améliorer BOPPER ? Envoyez-la ici ou contactez-nous directement depuis les
                                réseaux ou l’application. Nous serons très réactifs. </p>

                            <p className="reponse">Notre modèle repose également sur les retours de la communauté mais aussi de leurs attentes. Toutes les
                                idées sont bonnes alors n'hésitez pas. Nous lisons avec grand intérêt toutes vos suggestions et tous vos retours. Qui sait,
                                vous serez peut-être à l'origine d'une nouvelle fonctionnalité. </p>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    );

}

export default FAQ;