import '../css/Telechargement.css'
import navbarContext from "../context/navbarContext";
import React, {useContext, useLayoutEffect} from "react";
import video from "../assets/bg_download_light.mp4";
import videoDark from '../assets/video-dark.mp4'
import BopperLogoLight from '../assets/bopper-logo-white.webp';

import android from '../assets/android.webp';
import androidWhite from '../assets/android-white.webp';
import iosWhite from '../assets/ios-white.webp';
import ios from "../assets/ios.webp";
import QRCode from "react-qr-code";
import ThemeContext from "../context/ThemeContext";
import {useMediaQuery} from "react-responsive";

const detectOs = () => {
    const OS = ['Android', 'iPhone', 'Windows', 'Mac', 'Linux']
    const ua = window.navigator.userAgent
    for (const currOs of OS) {
        if (ua.toLowerCase().includes(currOs.toLowerCase())) {
            return currOs;
        }
    }
}

const currentOs = detectOs();

function Telechargement() {
    const {hauteurNavbar} = useContext(navbarContext);
    const {darkMode} = useContext(ThemeContext);
    const isMobile = useMediaQuery({query: '(max-width: 430px)'})
    const isIpad = useMediaQuery({query: '(max-width: 811px)'})

    useLayoutEffect(() => {
        document.getElementsByTagName('html')[0].classList.remove('overflowHtml');
        if (!isMobile && !isIpad) {
            document.getElementsByTagName('footer')[0].classList.add('footerFixed');
        } else document.getElementsByTagName('footer')[0].classList.remove('footerFixed');
    }, [darkMode])

    return (
        <div>
            <video controls={false} autoPlay={true} loop={true} muted={true} playsInline={true} className="background-dl" style={{display:darkMode?'none':'block'}}>
                <source src={video} type="video/mp4"/>
            </video>
            <video controls={false} autoPlay={true} loop={true} muted={true} playsInline={true} className="background-dl" style={{display:darkMode?'block':'none'}}>
                <source src={videoDark} type="video/mp4"/>
            </video>
            <div id="boutons-et-qrc" style={{height: "100vh", marginTop: -hauteurNavbar}}>
                <div className="titre-dl" style={{marginTop: hauteurNavbar}}>
                    <h1>
                        DÉCOUVREZ
                    </h1>
                    <img
                        src={BopperLogoLight}
                        alt="Le logo"
                        className="img-fluid logo"
                    />
                </div>
                <div className="row-dl">

                    <div className="div-qr" style={{background: 'white', padding: '10px'}}>
                        <QRCode size={160} className="qrc" value="https://bopper.fr/telecharger-bopper"/>
                    </div>
                    <div className="col-dl">
                        <a className="btn-dl" target="_blank" onClick={() => {
                            window.open('https://apps.apple.com/fr/app/bopper/id1578669466', '_blank')
                        }}>
                            <img alt="Télécharger sur l'App Store" className="img-fluid" src={darkMode?iosWhite:ios}/>
                        </a>
                        <a className="btn-dl" target="_blank" onClick={() => {
                            window.open('https://play.google.com/store/apps/details?id=fr.bopper&gl=FR', '_blank')
                        }}>
                            <img alt="Télécharger sur le Play Store" className="img-fluid" src={darkMode?androidWhite:android}/>
                        </a>
                        <button type="button" className="btn-dl-mobile" onClick={() => {
                            window.location = currentOs.toLowerCase() === 'iPhone'.toLowerCase() || currentOs.toLowerCase() === 'Mac'.toLowerCase() ? 'https://apps.apple.com/fr/app/bopper/id1578669466' : 'https://play.google.com/store/apps/details?id=fr.bopper&gl=FR'
                        }}>
                            <img alt="Télécharger" className="img-button-download-mobile img-fluid"
                                 src={currentOs.toLowerCase() === "iphone" || currentOs.toLowerCase() === "mac" ?darkMode?iosWhite:ios : darkMode?androidWhite:android}/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Telechargement;