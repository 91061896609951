import navbarContext from "../context/navbarContext";
import {useContext, useLayoutEffect} from "react";
import Pdf from '../pdf/CGU_Mangopay.pdf';
import ThemeContext from "../context/ThemeContext";
import logoMangoPay from '../assets/mangopay.webp';

function CGU(){
    const {hauteurNavbar}= useContext(navbarContext);
    const {darkMode} = useContext(ThemeContext);

    useLayoutEffect(() =>{

        document.documentElement.style.scrollBehavior = 'auto';
        setTimeout(() => window.scrollTo(0, 0), 5);
        setTimeout(() => document.documentElement.style.scrollBehavior = 'smooth', 5);
    })

    useLayoutEffect(() => {
        document.getElementsByTagName('html')[0].classList.remove('overflowHtml');
        document.getElementsByTagName('footer')[0].classList.remove('footerFixed');

    }, [])
    return (
        <div style={{marginTop:hauteurNavbar, background:darkMode?"rgb(26, 29, 32)":"white", color:darkMode?"white":"black"}}>
            <div className="container" >
                <div className={!darkMode?"content bg-white rounded p-3":"bg-dark content rounded p-3"} style={{padding: '3%'}}>
                    <h1 className="mt-3 mb-3">Conditions Générales d'Utilisation</h1>
                    <p className="text-end"><i>En vigueur au 05/07/2021</i></p>

                    <p>
                        Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet l'encadrement
                        juridique des modalités de mise à disposition du site et des services par Vitalytech et de
                        définir les conditions d’accès et d’utilisation des services par « l'Utilisateur ».<br/>
                        Les présentes CGU sont accessibles sur l’application à la rubrique « CGU ».
                    </p>

                    <p>
                        Toute inscription ou utilisation du site implique l'acceptation sans aucune réserve ni
                        restriction des présentes CGU par l’utilisateur. Lors de l'inscription sur le site via le
                        Formulaire d’inscription, chaque utilisateur accepte expressément les présentes CGU.
                        En cas de non-acceptation des CGU stipulées dans le présent contrat, l'Utilisateur se doit de
                        renoncer à l'accès des services proposés par l’application.
                        Bopper se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes
                        CGU.
                    </p>

                    <h2>1. Les mentions légales</h2>

                    <p>
                        Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en
                        l’économie numérique, il est précisé aux utilisateurs de l’application Bopper l’identité des
                        différents intervenants dans le cadre de sa réalisation et de son suivi.
                    </p>

                    <h3>Edition du site</h3>

                    <p>
                        L’application Bopper est éditée par la société Vitalytech, société à responsabilité limitée au
                        capital de 2.000,00 euros, dont le siège social est situé 56 avenue du Vieux Château, 59650
                        Villeneuve d’Ascq, immatriculée au registre du commerce et des sociétés sous le numéro
                        d’identification unique 882 778 426 RCS Lille Métropole.
                    </p>

                    <h3>Responsable de publication</h3>

                    <p>Cyril BOUCHERY</p>

                    <h3>Hébergeur</h3>

                    <p>
                        L’application Bopper est hébergée par la société 1 & 1 IONOS SARL
                        Adresse : 7 Place de la gare 57200 Sarreguemines
                        Le stockage des données personnelles des Utilisateurs est exclusivement réalisé sur les centres
                        de données dans des Etats membres de l’Union Européenne de la société 1 & 1 IONOS SARL
                    </p>

                    <h3>Nous contacter</h3>

                    <p>Par e-mail : info@Bopper.fr</p>

                    <h3>CNIL</h3>

                    <p>
                        La société Vitalytech conservera dans ses systèmes informatiques et dans des conditions
                        raisonnables de sécurité une preuve de la transaction comprenant le bon de commande et la
                        facture.
                        Conformément aux dispositions de la loi 78-17 du 6 janvier 1978 modifiée, l’Utilisateur dispose
                        d’un droit d’accès, de modification et de suppression des informations collectées par
                        Vitalytech. Pour exercer ce droit, il reviendra à l’Utilisateur d’envoyer un message à l’adresse
                        suivante : info@bopper.fr
                    </p>

                    <h2>2. Accès au site</h2>

                    <p>L’application Bopper est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à
                        Internet. Tous les frais supportés par l'Utilisateur pour accéder au service (matériel
                        informatique, logiciels, connexion Internet, etc.) sont à sa charge.</p>

                    <p>
                        L’Utilisateur non-membre n'a pas accès aux services réservés. Pour cela, il doit s’inscrire en
                        remplissant le formulaire. En acceptant de s’inscrire aux services réservés, l’Utilisateur
                        membre s’engage à fournir des informations sincères et exactes concernant son état civil et ses
                        coordonnées, notamment son adresse email.
                        Pour accéder aux services, l’Utilisateur doit ensuite s'identifier à l'aide de son identifiant
                        et de son mot de passe qui lui seront communiqués après son inscription.
                        Tout Utilisateur membre régulièrement inscrit pourra également solliciter sa désinscription en
                        se rendant à la page dédiée sur son espace personnel. Celle-ci sera effective dans un délai
                        raisonnable.
                        Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du site
                        ou serveur et sous réserve de toute interruption ou modification en cas de maintenance, n'engage
                        pas la responsabilité de Bopper. Dans ces cas, l’Utilisateur accepte ainsi ne pas tenir rigueur
                        à l’éditeur de toute interruption ou suspension de service, même sans préavis.
                        L'Utilisateur a la possibilité de contacter le site par messagerie électronique à l’adresse
                        email de l’éditeur communiqué à l’ARTICLE 1.
                    </p>

                    <h2>3. Inscription et création de compte</h2>

                    <p>L’utilisation de Plateforme est réservée aux personnes physiques âgées de 14 ans ou plus. Toute
                        inscription sur la Plateforme par une personne mineure est strictement interdite. En accédant,
                        utilisant ou vous inscrivant sur la Plateforme, vous déclarez et garantissez avoir 14 ans ou
                        plus.</p>

                    <p>La plateforme permet aux membres de publier des annonces de Test-Drive et consulter des annonces
                        ainsi que d’interagir entre eux pour la réservation. Vous pouvez consulter les annonces même si
                        vous n’êtes pas inscrit sur la plateforme. En revanche, vous ne pouvez ni publier une annonce de
                        Test-Drive ni en réserver une sans avoir, au préalable, créé un compte et être devenu
                        membre.</p>

                    <p>Pour créer votre Compte, vous pouvez :</p>
                    <ul>
                        <li> Soit remplir l’ensemble des champs obligatoires figurant sur le formulaire d’inscription
                        </li>
                        <li> Soit vous connecter, via notre Plateforme, à votre compte Facebook. En utilisant une telle
                            fonctionnalité, vous comprenez que Bopper aura accès, publiera sur la plateforme et
                            conservera certaines informations de votre compte Facebook. Vous pouvez à tout moment
                            supprimer le lien entre votre compte et votre compte Facebook. Si vous souhaitez en savoir
                            plus sur l’utilisation de vos données dans le cadre de votre Compte Facebook, consultez
                            notre Politique de Confidentialité et celle de Facebook.
                        </li>
                    </ul>

                    <p>Pour vous inscrire sur la plateforme, vous devez avoir lu et accepter les présentes CGU ainsi que
                        la Politique de Confidentialité.</p>

                    <p>A l’occasion de la création de votre compte, et ce quelle que soit la méthode choisie pour ce
                        faire, vous vous engagez à fournir des informations personnelles exactes et conformes à la
                        réalité et à les mettre à jour, par l’intermédiaire de votre profil ou en en avertissant Bopper,
                        afin d’en garantir la pertinence et l’exactitude tout au long de votre relation contractuelle
                        avec Bopper.</p>

                    <p>En cas d’inscription par email, vous vous engagez à garder secret le mot de passe choisi lors de
                        la création de votre compte et à ne le communiquer à personne. En cas de perte ou divulgation de
                        votre mot de passe, vous vous engagez à en informer sans délai Bopper. Vous êtes seul
                        responsable de l’utilisation faite de votre compte par un tiers, tant que vous n’avez pas
                        expressément notifié Bopper de la perte, l’utilisation frauduleuse par un tiers ou la
                        divulgation de votre mot de passe à un tiers.</p>

                    <p>Vous vous engagez à ne pas créer ou utiliser, sous votre propre identité ou celle d’un tiers,
                        d’autres Comptes que celui initialement créé.</p>

                    <h2>4. Utilisation des services</h2>

                    <p>En tant que Membre, et sous réserve que vous remplissiez les conditions ci-dessous, vous pouvez
                        créer et publier des annonces de Test-Drive sur la plateforme en indiquant des informations
                        quant au Test-Drive que vous comptez effectuer (dates/heures, options proposées, montant de la
                        participation aux frais, etc.).</p>

                    <p>Vous n’êtes autorisé à publier une annonce de Test-Drive que si vous remplissez l’ensemble des
                        conditions suivantes :</p>

                    <ul>
                        <li>Vous êtes titulaire d’un permis de conduire valide si vous êtes amené à conduire un véhicule
                            nécessitant un permis de conduire
                        </li>
                        <li>Vous ne proposez des annonces de Test-Drive que pour des véhicules dont vous êtes le
                            propriétaire ou que vous utilisez avec l’autorisation expresse du propriétaire, et dans tous
                            les cas, que vous êtes autorisés à utiliser à des fins de Test-Drive
                        </li>
                        <li>Vous n’avez aucune contre-indication ou incapacité médicale à conduire si vous proposez une
                            formule « passager » et que vous êtes le conducteur
                        </li>
                        <li>Vous utilisez un véhicule en parfait état de fonctionnement et conforme aux usages et
                            dispositions légales applicables
                        </li>
                        <li>Vous êtes un consommateur et n’agissez pas à titre professionnel.</li>
                        <li>Vous reconnaissez être le seul responsable du contenu de l’annonce de Test-Drive que vous
                            publiez sur la plateforme. En conséquence, vous déclarez et garantissez l’exactitude et la
                            véracité de toute information contenue dans votre annonce de Test-Drive et vous engagez à
                            effectuer le Test-Drive selon les modalités décrites dans votre annonce de Test-Drive.
                        </li>
                    </ul>

                    <p>
                        Dans tous les cas, c'est le contrat d'assurance du propriétaire du véhicule qui s'applique. De
                        ce fait, dans le cas où vous proposez à quelqu'un de prendre le volant, il est recommandé de
                        souscrire à la garantie « prêt volant » pour les conducteurs qui essayent le véhicule. Cette
                        garantie permettra de couvrir en cas de sinistre les dommages causés au véhicule. BOPPER ne peut
                        être tenu responsable si nous n'avez pas fait le nécessaire auprès de votre assureur.
                    </p>

                    <p>Votre Annonce de Test-Drive sera publiée sur la Plateforme et donc visible des membres et de tous
                        visiteurs, même non-membre, effectuant une recherche sur la plateforme Bopper. Bopper se réserve
                        la possibilité, à sa seule discrétion et sans préavis, de ne pas publier ou retirer, à tout
                        moment, toute annonce de covoiturage qui ne serait pas conforme aux CGU ou qu’elle considérerait
                        comme préjudiciable à son image, celle de la plateforme ou celle des services.</p>

                    <p>Vous êtes également informés de ce que dans le cas où vous vous présenteriez en tant que
                        consommateur en utilisant la Plateforme alors que vous agissez en réalité à titre professionnel
                        vous vous exposez aux sanctions prévues à l’article L.132-2 du Code de la consommation.</p>

                    <h2>5. Conditions financières</h2>

                    <p>L’accès et l’inscription à la plateforme, de même que la recherche, la consultation et la
                        publication d’Annonces sont gratuits. En revanche, la réservation est payante dans les
                        conditions décrites ci-dessous.</p>

                    <h3>Participation aux Frais et Prix</h3>

                    <p>Dans le cadre d’un Test-Drive, le montant de la participation aux frais est déterminé par vous,
                        en tant que propriétaire, sous votre seule responsabilité. Il est strictement interdit de tirer
                        le moindre bénéfice du fait de l’utilisation de notre plateforme. Par conséquent, vous vous
                        engagez à limiter le montant de la participation aux frais que vous demandez aux utilisateurs
                        aux frais que vous supportez réellement pour effectuer le Test-Drive.</p>

                    <h3>Frais de Service</h3>

                    <p>Bopper peut prélever, en contrepartie de l’utilisation de la Plateforme, des frais de service à
                        ses utilisateurs. L’utilisateur sera informé avant toute application de Frais de Service le cas
                        échéant.</p>

                    <p>Les modalités de calcul des frais de service en vigueur accessibles ici sont fournies à titre
                        informatif uniquement et n’ont pas de valeur contractuelle. Bopper se réserve le droit de
                        modifier à tout moment les modalités de calcul des frais de service. Ces modifications n’auront
                        pas d’effet sur les frais de service acceptés par les utilisateurs avant la date de prise
                        d’effet de ces modifications.</p>

                    <h3>Arrondis</h3>

                    <p>Vous reconnaissez et acceptez que Bopper peut, à son entière discrétion, arrondir au chiffre
                        inférieur ou supérieur les Frais de Service et la Participation aux Frais.</p>

                    <h3>Modalités de paiement et de reversement de la participation aux frais</h3>

                    <p>En utilisant la plateforme en tant que propriétaire pour des Test-Drive, vous confiez à Bopper un
                        mandat d’encaissement du montant de la participation aux frais en votre nom et pour votre
                        compte.</p>

                    <p>Par conséquent, dans le cadre d’un Test-Drive, et après acceptation manuelle de la réservation,
                        Bopper encaisse la totalité de la somme versée par l’usager (frais de service et participation
                        aux frais).</p>

                    <p>Les participations aux frais reçues par Bopper sont déposées sur un compte dédié au paiement des
                        Propriétaires.</p>

                    <p>Vous reconnaissez et acceptez qu’aucune des sommes perçues par Bopper au nom et pour le compte du
                        propriétaire n’emporte droit à intérêts. Vous acceptez de répondre avec diligences à toute
                        demande de Bopper et plus généralement de toute autorité administrative ou judiciaire compétente
                        en particulier en matière de prévention ou la lutte contre le blanchiment. Notamment, vous
                        acceptez de fournir, sur simple demande, tout justificatif d’adresse et/ou d’identité utile.</p>

                    <p>En l’absence de réponse de votre part à ces demandes, Bopper pourra prendre toute mesure qui lui
                        semblera appropriée notamment le gel des sommes versées et/ou la suspension de votre Compte
                        et/ou la résiliation des présentes CGU.</p>

                    <h3>Versement de la Participation aux Frais au Propriétaire</h3>

                    <p>A la suite du Test-Drive, les Passagers disposent d’un délai de 24 heures pour présenter une
                        réclamation à Bopper. En l’absence de contestation de leur part dans cette période, Bopper
                        considère la confirmation du Test-Drive comme acquise.</p>

                    <p>A compter de cette confirmation expresse ou tacite, vous disposez, en tant que propriétaire, d’un
                        crédit exigible sur votre Compte. Ce crédit correspond au montant total payé par le Passager au
                        moment de la confirmation de la Réservation diminué des Frais de Service, c’est-à-dire au
                        montant de la Participation aux Frais payée par le Passager.</p>

                    <p>Lorsque le Test-Drive est confirmé par les deux parties, vous recevrez, en tant que propriétaire,
                        de donner instructions à Bopper de vous verser l’argent sur votre compte bancaire (en
                        renseignant sur votre compte, au préalable, vos coordonnées bancaires).</p>

                    <p>L’ordre de virement à votre nom sera transmis le premier jour ouvré suivant votre demande ou à
                        défaut de demande de votre part, le premier jour ouvré suivant la mise à disposition sur votre
                        profil des sommes concernées (sous réserve que Bopper dispose de vos informations
                        bancaires).</p>

                    <p>A l’issue du délai de prescription de 5 ans applicable, toute somme non réclamée à Bopper sera
                        réputée appartenir à Bopper.</p>

                    <h2>6. Politique d’annulation</h2>


                    <h3>Modalités de remboursement en cas d’annulation</h3>

                    <p>L’annulation d’un Test-Drive par le propriétaire ou l’usager après la confirmation de réservation
                        est soumise aux stipulations ci-après :</p>

                    <ul>
                        <li>En cas d’annulation imputable au propriétaire, l’usager est remboursé de la totalité de la
                            somme qu’il a versée (c’est-à-dire la Participation aux Frais et les Frais de Service
                            afférents). C’est notamment le cas lorsque le propriétaire annule un Test-Drive ou ne se
                            rend pas au point de rendez-vous au plus tard 15 minutes après l’horaire convenu
                        </li>
                    </ul>

                    <p>En cas d’annulation imputable à l’usager :</p>

                    <ul>
                        <li>Si l’usager annule plus de 72 heures avant l’heure prévue pour le départ telle que
                            mentionnée dans l’annonce du Test-Drive, l’usager est remboursé du montant de la
                            participation aux frais. Les frais de service demeurent acquis à Bopper et le Conducteur ne
                            reçoit aucune somme de quelque nature que ce soit
                        </li>

                        <li>Si l’usager annule moins de 24 heures ou 24 heures avant l’heure prévue pour le départ,
                            telle que mentionnée dans l’annonce de Test-Drive et plus de trente minutes après la
                            confirmation de réservation, l’usager est remboursé à hauteur de la moitié de la
                            participation aux frais versée lors de la réservation, les frais de service demeurent acquis
                            à Bopper et le propriétaire reçoit 50% de la participation aux frais.
                        </li>

                        <li>Si l’usager annule moins de 24 heures ou 24 heures avant l’heure prévue pour le départ,
                            telle que mentionnée dans l’annonce de Test-Drive et trente minutes ou moins après la
                            confirmation de réservation, l’usager est remboursé de l’intégralité de la participation aux
                            frais. Les frais de service demeurent acquis à Bopper et le propriétaire ne reçoit aucune
                            somme de quelque nature que ce soit
                        </li>

                        <li>Si l’usager annule après l’heure prévue pour le départ, telle que mentionnée dans l’annonce,
                            ou s’il ne se présente pas au lieu de rendez-vous au plus tard dans un délai de 15 minutes à
                            compter de l’heure convenue, aucun remboursement n’est effectué. Le propriétaire est
                            dédommagé à hauteur de la totalité de la participation aux frais et les frais de services
                            sont conservés par Bopper.
                        </li>
                    </ul>

                    <p>Bopper apprécie à sa seule discrétion, sur la base des éléments à sa disposition, la légitimité
                        des demandes de remboursement qu’elle reçoit.</p>

                    <h3>Droit de rétraction</h3>

                    <p>En acceptant les présentes CGU, vous acceptez expressément que le contrat entre vous et Bopper
                        consistant en la mise en relation avec un autre membre soit exécuté avant l’expiration du délai
                        de rétractation dès la confirmation de réservation et renoncez expressément à votre droit de
                        rétraction, conformément aux dispositions de l’article L.221-28 du Code de la consommation.</p>

                    <h2>7. Comportement des utilisateurs de la Plateforme et Membres</h2>

                    <h3>Engagement de tous les utilisateurs de la Plateforme</h3>

                    <p>Vous reconnaissez être seul responsable du respect de l’ensemble des lois, règlements et
                        obligations applicables à votre utilisation de la plateforme.</p>

                    <p>Par ailleurs, en utilisant la plateforme et lors des Test-Drive, vous vous engagez à :</p>

                    <ul>
                        <li>Ne pas utiliser la plateforme à des fins professionnelles, commerciales ou lucratives</li>

                        <li>Ne transmettre à Bopper (notamment lors de la création ou la mise à jour de votre compte) ou
                            aux autres membres aucune information fausse, trompeuse, mensongère ou frauduleuse
                        </li>

                        <li>Ne tenir aucun propos, n’avoir aucun comportement ou ne publier sur la plateforme aucun
                            contenu à caractère diffamatoire, injurieux, obscène, pornographique, vulgaire, offensant,
                            agressif, déplacé, violent, menaçant, harcelant, raciste, xénophobe, à connotation sexuelle,
                            incitant à la violence, à la discrimination ou à la haine, encourageant les activités ou
                            l’usage de substances illégales ou, plus généralement, contraires aux finalités de la
                            Plateforme, de nature à porter atteinte aux droits de Bopper ou d’un tiers ou contraires aux
                            bonnes mœurs.
                        </li>

                        <li>Ne pas porter atteinte aux droits et à l’image de Bopper, notamment à ses droits de
                            propriété intellectuelle
                        </li>

                        <li>Ne pas ouvrir plus d’un compte sur la plateforme et ne pas ouvrir de compte au nom d’un
                            tiers
                        </li>

                        <li>Ne pas tenter de contourner le système de réservation en ligne de la plateforme, notamment
                            en tentant de communiquer à un autre membre vos coordonnées afin de réaliser la réservation
                            en dehors de la plateforme et ne pas payer les frais de service
                        </li>

                        <li>Ne pas contacter un autre membre, notamment par l’intermédiaire de la plateforme, à une
                            autre fin que celle de définir les modalités du Test-Drive
                        </li>

                        <li>Ne pas accepter ou effectuer un paiement en dehors de la plateforme</li>

                        <li>Vous conformer aux présentes CGU et à la Politique de Confidentialité.</li>
                    </ul>

                    <h2>8. Collecte des données</h2>

                    <p>
                        Le site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le
                        respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à
                        l'informatique, aux fichiers et aux libertés.<br/>
                        En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose
                        d'un droit d'accès, de rectification, de suppression et d'opposition de ses données
                        personnelles. L'Utilisateur exerce ce droit :
                    </p>

                    <ul>
                        <li>Par mail à l'adresse email info@bopper.fr</li>
                        <li>Via son espace personnel</li>
                    </ul>

                    <h2>9. Propriété intellectuelle</h2>

                    <p>Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son…) font
                        l'objet d'une protection par le Code de la propriété intellectuelle et plus particulièrement par
                        le droit d'auteur.</p>

                    <p>La marque BOPPER est une marque déposée par Vitalytech, SARL. Toute représentation et/ou
                        reproduction et/ou exploitation partielle ou totale de cette marque, de quelque nature que ce
                        soit, est totalement prohibée.</p>

                    <p>
                        L'Utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction,
                        publication, copie des différents contenus. Il s'engage à une utilisation des contenus du site
                        dans un cadre strictement privé, toute utilisation à des fins commerciales et publicitaires est
                        strictement interdite.<br/>
                        Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans
                        l’autorisation expresse de l’exploitant du site Internet constituerait une contrefaçon
                        sanctionnée par l’article L 335-2 et suivants du Code de la propriété intellectuelle.<br/>
                        Il est rappelé conformément à l’article L122-5 du Code de propriété intellectuelle que
                        l’Utilisateur qui reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa
                        source.
                    </p>

                    <h2>10. Responsabilité</h2>

                    <p>
                        Les sources des informations diffusées sur l’application Bopper sont réputées fiables mais
                        l’application ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions.<br/>
                        Les informations communiquées sont présentées à titre indicatif et général sans valeur
                        contractuelle. Malgré des mises à jour régulières, l’application ne peut être tenu responsable
                        de la modification des dispositions administratives et juridiques survenant après la
                        publication. De même, le site ne peut être tenue responsable de l’utilisation et de
                        l’interprétation de l’information contenue dans ce site.<br/>
                        L'Utilisateur s'assure de garder son mot de passe secret. Toute divulgation du mot de passe,
                        quelle que soit sa forme, est interdite. Il assume les risques liés à l'utilisation de son
                        identifiant et mot de passe. L’application décline toute responsabilité.<br/>
                        L’application ne peut être tenu pour responsable d’éventuels virus qui pourraient infecter
                        l’ordinateur ou tout matériel informatique de l’Internaute, pour donner suite à une utilisation,
                        à l’accès, ou au téléchargement provenant de ce site.<br/>
                        La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible
                        et insurmontable d'un tiers.
                    </p>

                    <h2>11. Liens hypertextes</h2>

                    <p>Des liens hypertextes peuvent être présents sur le site. L’Utilisateur est informé qu’en cliquant
                        sur ces liens, il sortira de l’application Bopper. Ce dernier n’a pas de contrôle sur les pages
                        web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur
                        contenu.</p>

                    <h2>12. Publication par l’Utilisateur</h2>

                    <p>
                        Dans ses publications, le membre s’engage à respecter les règles de la Netiquette (règles de
                        bonne conduite de l’internet) et les règles de droit en vigueur.<br/>
                        Le site peut exercer une modération sur les publications et se réserve le droit de refuser leur
                        mise en ligne, sans avoir à s’en justifier auprès du membre.<br/>
                        Le membre reste titulaire de l’intégralité de ses droits de propriété intellectuelle. Mais en
                        publiant une publication sur l’application, il cède à la société éditrice le droit non exclusif
                        et gratuit de représenter, reproduire, adapter, modifier, diffuser et distribuer sa publication,
                        directement ou par un tiers autorisé, dans le monde entier, sur tout support (numérique ou
                        physique), pour la durée de la propriété intellectuelle. Le Membre cède notamment le droit
                        d'utiliser sa publication sur internet et sur les réseaux de téléphonie mobile.<br/>
                        La société éditrice s'engage à faire figurer le nom du membre à proximité de chaque utilisation
                        de sa publication.<br/>
                        Tout contenu mis en ligne par l'Utilisateur est de sa seule responsabilité. L'Utilisateur
                        s'engage à ne pas mettre en ligne de contenus pouvant porter atteinte aux intérêts de tierces
                        personnes. Tout recours en justice engagé par un tiers lésé contre le site sera pris en charge
                        par l'Utilisateur.<br/>
                        Le contenu de l'Utilisateur peut être à tout moment et pour n'importe quelle raison supprimé ou
                        modifié par le site, sans préavis.
                    </p>

                    <h2>13. Droit applicable et juridiction compétente</h2>

                    <p>
                        La législation française s'applique au présent contrat. En cas d'absence de résolution amiable
                        d'un litige né entre les parties, les tribunaux français seront seuls compétents pour en
                        connaître.<br/>
                        Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur
                        aux coordonnées inscrites à l’article 1.
                    </p>

                    <h2>14. Mangopay</h2>

                    <p>
                        Notre service de paiement est géré par la société Mangopay.
                    </p>

                    <p>Voir les <a href={Pdf}>CGU</a> de Mangopay.</p>

                    <img className="img-fluid" src={logoMangoPay} alt="logo"  />
                </div>
            </div>
        </div>

    );
}
export default CGU;