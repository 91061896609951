import Lottie from "lottie-react";
import qrScan from '../assets/qr-scan.json';


const detectOs = () => {
    const OS = ['Android', 'iPhone', 'Windows', 'Mac', 'Linux']
    const ua = window.navigator.userAgent
    for(const currOs of OS) {
        if (ua.toLowerCase().includes(currOs.toLowerCase())) {
            return currOs;
        }
    }
}

const currentOs = detectOs();


function Redirect(){
    return(
        <div style={{objectFit: 'contain', height: '100vh'}} value={window.location = currentOs.toLowerCase() === 'iPhone'.toLowerCase() || currentOs.toLowerCase() === 'Mac'.toLowerCase() ? 'https://apps.apple.com/fr/app/bopper/id1578669466' : 'https://play.google.com/store/apps/details?id=fr.bopper&gl=FR'}>
            <Lottie style={{position:'absolute', top: 0, bottom: 0, left:'0', right: '0'}} autoplay={true} loop={true} animationData={qrScan} />
        </div>
    );
} export default Redirect;