import loadingImage from '../assets/loading.gif';
import '../css/Loading.css';
import {useContext} from "react";
import ThemeContext from "../context/ThemeContext";

function Loading() {

    const {darkMode} = useContext(ThemeContext);

    return(
        <div className={"loading"} style={{backgroundColor:darkMode?"#212529":"#DEDEDE"}}>
            <img className={"loading-img"} src={loadingImage} alt={"Chargement"}/>
        </div>
    );

}

export default Loading;