import '../css/Navbar.css';
import Sun from '../assets/theme/contrast(1).png'
import Moon from '../assets/theme/moon(2).png'
import BopperLogo from '../assets/bopper_sf_black.webp';
import BopperLogoDark from '../assets/bopper-logo-white.webp'
import React, {useContext, useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import Switch from "react-switch";
import NavbarContext from "../context/navbarContext";
import FlashMessage from 'react-flash-message';
import useResize from "../hooks/useResize";
import {useMediaQuery} from "react-responsive";
import ThemeContext from "../context/ThemeContext";
import Loading from "./Loading";

function Navbar() {

    const {hauteurNavbar, setHauteurNavbar} = useContext(NavbarContext);
    const isMobile = useMediaQuery({query: `(max-width: 960px)`});
    const navbarRef = useRef();
    const switchRef = useRef();
    const resize = useResize(navbarRef);
    const [loaded, setLoaded] = useState(false);
    const {darkMode, theme} = useContext(ThemeContext);
    const [show, setShow] = useState(true);
    let timeout = null;

    useEffect(() => {
        setTimeout(() => setShow(false), 2000);
    }, [show])

    useEffect(() => {
        if (isMobile) {
            const links = document.getElementsByClassName('nav-link')

            if (loaded) {
                for (const link of links) {
                    link.addEventListener('click', () => {
                        const button = document.getElementsByClassName('navbar-toggler')[0];
                        if (document.getElementById('navbarNavAltMarkup').classList.contains('show')) button.click();
                    });
                }
            }
        }
    }, [navbarRef, loaded, isMobile]);


    useEffect(() => {
        loaded && resize.rect && setHauteurNavbar(resize.rect.height);
    }, [loaded, resize, setHauteurNavbar])


    const loadingScreen = () => {
        clearTimeout(timeout)
        timeout = null;
        setShow(true);
        theme(!darkMode);
        timeout = setTimeout(() => {
            setShow(false);
        }, 2000);
    }


    const HandleChange = () => (<FlashMessage duration={2000}>
        <Loading/>
    </FlashMessage>)

    return (<NavbarContext.Provider value={{hauteurNavbar}}>

        <div>
            {show && <HandleChange/>}
        </div>
        <div className="App">
            <nav onLoad={() => {
                setLoaded(true)
            }} ref={navbarRef} style={{zIndex: 2}}
                 className={!darkMode ? "navbar navbar-expand-lg navbar-light bg-light customNavbar" : "navbar navbar-expand-lg navbar-dark bg-dark customNavbar-dark"}>
                <div className="container-fluid">
                    <Link className="navbar-brand nav-link" style={{padding: '0'}} to="/">
                        <img
                            src={!darkMode ? BopperLogo : BopperLogoDark}
                            alt="Le logo"
                            height="auto"
                            width="100px"/>

                    </Link>
                    {isMobile && <div style={{width: '100px'}}>
                        <Switch onColor={'#0C73A4'} onChange={loadingScreen} checked={!darkMode}
                                onClick={loadingScreen}
                                uncheckedIcon={<div className={'div-icons'}><img src={Moon}
                                                                                 alt={"lune"}
                                                                                 style={{
                                                                                     width: "70%", height: '70%'
                                                                                 }}/></div>}
                                checkedIcon={<div className={'div-icons'}><img src={Sun}
                                                                               alt="soleil"
                                                                               style={{
                                                                                   width: "70%", height: '70%'
                                                                               }}/></div>}/>
                    </div>}
                    <button className="navbar-toggler button-navbar" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup"
                            aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            <Link className="nav-link" to="/faq">FAQ</Link>
                            <Link className="nav-link" to="/contactez-nous">Contactez-nous</Link>
                            <Link className="nav-link" to="/telecharger">Télécharger</Link>

                            {!isMobile && <div style={{
                                position: "absolute",
                                right: '0',
                                width: "15%",
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                verticalAlign: "middle !important",
                                top: '0'
                            }}>
                                <Switch ref={switchRef} width={70} onColor={'#0C73A4'}
                                        onChange={loadingScreen} checked={!darkMode}
                                        onClick={loadingScreen}
                                        uncheckedIcon={<div className={'div-icons'}><img
                                            alt="Lune" src={Moon} style={{
                                            width: "50%", height: '70%'
                                        }}/></div>} checkedIcon={<div className={'div-icons'}><img src={Sun}
                                                                                                   alt={"soleil"}
                                                                                                   style={{
                                                                                                       width: "50%",
                                                                                                       height: '70%'
                                                                                                   }}/>
                                </div>}/>
                            </div>}
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </NavbarContext.Provider>);
}

export default Navbar;