import navbarContext from "../context/navbarContext";
import {useContext, useLayoutEffect} from "react";
import ThemeContext from "../context/ThemeContext";


function PDC(){
    const {hauteurNavbar }= useContext(navbarContext);
    const {darkMode} = useContext(ThemeContext);

    useLayoutEffect(() =>{
        document.documentElement.style.scrollBehavior = 'auto';
        setTimeout(() => window.scrollTo(0, 0), 5);
        setTimeout(() => document.documentElement.style.scrollBehavior = 'smooth', 5);
    })

    useLayoutEffect(() => {
        document.getElementsByTagName('html')[0].classList.remove('overflowHtml');
        document.getElementsByTagName('footer')[0].classList.remove('footerFixed');

    }, [])
    return(
        <div style={{marginTop:hauteurNavbar, background:darkMode?"rgb(26, 29, 32)":"white", color:darkMode?"white":"black"}}>
            <div className="container" >
                <div className={!darkMode?"content bg-white rounded p-3":"bg-dark content rounded p-3"} style={{padding: '3%'}}>
                    <h1 className="mt-3 mb-3 ">Politique de Confidentialité</h1>
                    <h2>ARTICLE 1 : PREAMBULE</h2>

                    <p>Cette politique de confidentialité s'applique au site : Bopper.</p>

                    <p>La présente politique de confidentialité a pour but d'exposer aux utilisateurs du site :</p>

                    <ul>
                        <li>La manière dont sont collectées et traitées leurs données à caractère personnel. Doivent
                            être considérées comme données personnelles toutes les données étant susceptibles
                            d'identifier un utilisateur. Il s'agit notamment du prénom et du nom, de l'âge, de l'adresse
                            postale, l'adresse mail, la localisation de l'utilisateur ou encore son adresse IP ;
                        </li>

                        <li>Quels sont les droits des utilisateurs concernant ces données ;</li>

                        <li>Qui est responsable du traitement des données à caractère personnel collectées et traitées
                            ;
                        </li>

                        <li>A qui ces données sont transmises ;</li>

                        <li>Eventuellement, la politique du site en matière de fichiers "cookies".</li>
                    </ul>

                    <p>
                        Cette politique de confidentialité complète les mentions légales et les Conditions Générales
                        d'Utilisation que les utilisateurs peuvent consulter à l'adresse ci-après :
                    </p>

                    <p><a href="https://bopper.fr/cgu">https://bopper.fr/cgu</a></p>

                    <h2>ARTICLE 2 : PRINCIPES GÉNÉRAUX EN MATIÈRE DE COLLECTE ET DE TRAITEMENT DE DONNÉES</h2>

                    <p>Conformément aux dispositions de l'article 5 du Règlement européen 2016/679, la collecte et le
                        traitement des données des utilisateurs du site respectent les principes suivants :</p>

                    <ul>
                        <li>Licéité, loyauté et transparence : les données ne peuvent être collectées et traitées
                            qu'avec le consentement de l'utilisateur propriétaire des données. A chaque fois que des
                            données à caractère personnel seront collectées, il sera indiqué à l'utilisateur que ses
                            données sont collectées, et pour quelles raisons ses données sont collectées ;
                        </li>

                        <li>Finalités limitées : la collecte et le traitement des données sont exécutés pour répondre à
                            un ou plusieurs objectifs déterminés dans les présentes conditions générales d'utilisation ;
                        </li>

                        <li>Minimisation de la collecte et du traitement des données : seules les données nécessaires à
                            la bonne exécution des objectifs poursuivis par le site sont collectées ;
                        </li>

                        <li>Conservation des données réduites dans le temps : les données sont conservées pour une durée
                            limitée, dont l'utilisateur est informé. Lorsque cette information ne peut pas être
                            communiquée, l'utilisateur est informé des critères utilisés pour déterminer la durée de
                            conservation ;
                        </li>

                        <li>Intégrité et confidentialité des données collectées et traitées : le responsable du
                            traitement des données s'engage à garantir l'intégrité et la confidentialité des données
                            collectées.
                        </li>
                    </ul>

                    <p>Afin d'être licites, et ce conformément aux exigences de l'article 6 du règlement européen
                        2016/679, la collecte et le traitement des données à caractère personnel ne pourront intervenir
                        que s'ils respectent au moins l'une des conditions ci-après énumérées :</p>

                    <ul>
                        <li>L'utilisateur a expressément consenti au traitement ;</li>

                        <li>Le traitement est nécessaire à la bonne exécution d'un contrat ;</li>

                        <li>Le traitement répond à une obligation légale ;</li>

                        <li>Le traitement s'explique par une nécessité liée à la sauvegarde des intérêts vitaux de la
                            personne concernée ou d'une autre personne physique ;
                        </li>

                        <li>Le traitement peut s'expliquer par une nécessité liée à l'exécution d'une mission d'intérêt
                            public ou qui relève de l'exercice de l'autorité publique ;
                        </li>

                        <li>Le traitement et la collecte des données à caractère personnel sont nécessaires aux fins des
                            intérêts légitimes et privés poursuivis par le responsable du traitement ou par un tiers.
                        </li>
                    </ul>

                    <h2>ARTICLE 3 : DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES ET TRAITÉES DANS LE CADRE DE LA NAVIGATION
                        SUR LE SITE</h2>

                    <h3>A. DONNÉES COLLECTÉES ET TRAITÉES ET MODE DE COLLECTE</h3>

                    <p>Les données à caractère personnel collectées sur le site Bopper sont les suivantes :</p>

                    <p>
                        - Nom et prénom<br/>
                        - Adresse mail<br/>
                        - Numéro de téléphone<br/>
                        - Code postal<br/>
                        - Date de naissance<br/>
                        - Photo de profil<br/>
                        - Informations du/des véhicule(s)<br/>
                        - Photo(s) du/des véhicule(s)<br/>
                        - Les informations bancaires
                    </p>

                    <p>Ces données sont collectées lorsque l'utilisateur effectue l'une des opérations suivantes sur le
                        site :</p>

                    <p>
                        - L'inscription<br/>
                        - La modification du profil<br/>
                        - L'ajout d'un véhicule<br/>
                        - L'ajout d'un système de paiement
                    </p>

                    <p>Par ailleurs, lors d'un paiement sur le site, il sera conservé dans les systèmes informatiques de
                        l'éditeur du site une preuve de la transaction comprenant le bon de commande et la facture.</p>

                    <p>Le responsable du traitement conservera dans ses systèmes informatiques du site et dans des
                        conditions raisonnables de sécurité l'ensemble des données collectées.</p>

                    <p>La collecte et le traitement des données répondent aux finalités suivantes :</p>

                    <p>
                        - Traitement des réservations<br/>
                        - Gestion des profils<br/>
                        - Validation des paiements<br/>
                        - Certification des membres
                    </p>

                    <p>Les traitements de données effectués sont fondés sur les bases légales suivantes :</p>

                    <p>
                        - Consentement de l'utilisateur<br/>
                        - Validation des données<br/>
                        - Obligations légales
                    </p>

                    <h3>B. TRANSMISSION DES DONNÉES A DES TIERS</h3>

                    <p>Les données à caractère personnel collectées par le site ne sont transmises à aucun tiers, et ne
                        sont traitées que par l'éditeur du site.</p>

                    <h3>C. HÉBERGEMENT DES DONNÉES</h3>

                    <p>
                        Le site Bopper est hébergé par : Ionos, dont le siège est situé à l'adresse ci-après :<br/>
                        7 Place de la gare 57200 Sarreguemines<br/>
                        L'hébergeur peut être contacté au numéro de téléphone suivant : 0970 808 911.<br/>
                        Les données collectées et traitées par le site sont exclusivement hébergées et traitées en
                        France.
                    </p>

                    <h2>ARTICLE 4 : RESPONSABLE DU TRAITEMENT DES DONNÉES</h2>

                    <h3>A. LE RESPONSABLE DU TRAITEMENT DES DONNÉES</h3>

                    <p>Le responsable du traitement des données à caractère personnel est : SARL Vitalytech. Il peut
                        être contacté de la manière suivante :</p>

                    <p>- Par mail à l'adresse suivante : info@vitalytech.com</p>

                    <p>Le responsable du traitement des données est chargé de déterminer les finalités et les moyens mis
                        au service du traitement des données à caractère personnel.</p>

                    <h3>B. OBLIGATIONS DU RESPONSABLE DU TRAITEMENT DES DONNÉES</h3>

                    <p>Le responsable du traitement s'engage à protéger les données à caractère personnel collectées, à
                        ne pas les transmettre à des tiers sans que l'utilisateur n'en ait été informé et à respecter
                        les finalités pour lesquelles ces données ont été collectées.</p>

                    <p>De plus, le responsable du traitement des données s'engage à notifier l'utilisateur en cas de
                        rectification ou de suppression des données, à moins que cela n'entraîne pour lui des
                        formalités, coûts et démarches disproportionnés.</p>

                    <p>Dans le cas où l'intégrité, la confidentialité ou la sécurité des données à caractère personnel
                        de l'utilisateur est compromise, le responsable du traitement s'engage à informer l'utilisateur
                        par tout moyen.</p>

                    <h2>ARTICLE 5 : DROITS DE L'UTILISATEUR</h2>

                    <p>Conformément à la réglementation concernant le traitement des données à caractère personnel,
                        l'utilisateur possède les droits ci-après énumérés.</p>

                    <p>Afin que le responsable du traitement des données fasse droit à sa demande, l'utilisateur est
                        tenu de lui communiquer : ses prénom et nom ainsi que son adresse e-mail, et si cela est
                        pertinent, son numéro de compte ou d'espace personnel ou d'abonné.</p>

                    <p>Le responsable du traitement des données est tenu de répondre à l'utilisateur dans un délai de 30
                        (trente) jours maximums.</p>

                    <h3>A. PRÉSENTATION DES DROITS DE L'UTILISATEUR EN MATIÈRE DE COLLECTE ET TRAITEMENT DE DONNÉES</h3>

                    <b>a. Droit d'accès, de rectification et droit à l'effacement</b>

                    <p>L'utilisateur peut prendre connaissance, mettre à jour, modifier ou demander la suppression des
                        données le concernant, en respectant la procédure ci-après énoncée :</p>

                    <p>L'utilisateur doit envoyer un e-mail au responsable du traitement des données personnelles, en
                        précisant l'objet de sa demande, à l'adresse e-mail de contact.</p>

                    <p>S'il en possède un, l'utilisateur a le droit de demander la suppression de son espace personnel
                        en suivant la procédure suivante :</p>

                    <p>L'utilisateur doit envoyer un e-mail au responsable du traitement en précisant son numéro
                        d'espace personnel. La demande sera traitée dans un délai de 10 jours ouvrés.</p>

                    <b>b. Droit à la portabilité des données</b>

                    <p>L'utilisateur a le droit de demander la portabilité de ses données personnelles, détenues par le
                        site, vers un autre site, en se conformant à la procédure ci-après :</p>

                    <p>L'utilisateur doit faire une demande de portabilité de ses données personnelles auprès du
                        responsable du traitement des données, en envoyant un e-mail à l'adresse prévue ci-dessus.</p>


                    <b>c. Droit à la limitation et à l'opposition du traitement des données</b>

                    <p>L'utilisateur a le droit de demander la limitation ou de s'opposer au traitement de ses données
                        par le site, sans que le site ne puisse refuser, sauf à démontrer l'existence de motifs
                        légitimes et impérieux, pouvant prévaloir sur les intérêts et les droits et libertés de
                        l'utilisateur.</p>

                    <p>Afin de demander la limitation du traitement de ses données ou de formuler une opposition au
                        traitement de ses données, l'utilisateur doit suivre la procédure suivante :</p>

                    <p>L'utilisateur doit faire une demande de limitation au traitement de ses données personnelles par
                        e-mail auprès du responsable du traitement des données.</p>

                    <b>d. Droit de ne pas faire l'objet d'une décision fondée exclusivement sur un procédé
                        automatisé</b>

                    <p>Conformément aux dispositions du règlement 2016/679, l'utilisateur a le droit de ne pas faire
                        l'objet d'une décision fondée exclusivement sur un procédé automatisé si la décision produit des
                        effets juridiques le concernant, ou l'affecte de manière significative de façon similaire.</p>


                    <b>e. Droit de déterminer le sort des données après la mort</b>

                    <p>Il est rappelé à l'utilisateur qu'il peut organiser quel doit être le devenir de ses données
                        collectées et traitées s'il décède, conformément à la loi n°2016-1321 du 7 octobre 2016.</p>

                    <b>f. Droit de saisir l'autorité de contrôle compétente</b>

                    <p>Dans le cas où le responsable du traitement des données décide de ne pas répondre à la demande de
                        l'utilisateur, et que l'utilisateur souhaite contester cette décision, ou, s'il pense qu'il est
                        porté atteinte à l'un des droits énumérés ci-dessus, il est en droit de saisir la CNIL
                        (Commission Nationale de l'Informatique et des Libertés, https://www.cnil.fr) ou tout juge
                        compétent.</p>

                    <h3>B. DONNÉES PERSONNELLES DES PERSONNES MINEURES</h3>

                    <p>Conformément aux dispositions de l'article 8 du règlement européen 2016/679 et à la loi
                        Informatique et Libertés, seuls les mineurs âgés de 15 ans ou plus peuvent consentir au
                        traitement de leurs données personnelles.</p>

                    <p>Si l'utilisateur est un mineur de moins de 15 ans, l'accord d'un représentant légal sera requis
                        afin que des données à caractère personnel puissent être collectées et traitées.</p>

                    <p>L'éditeur du site se réserve le droit de vérifier par tout moyen que l'utilisateur est âgé de
                        plus de 15 ans, ou qu'il aura obtenu l'accord d'un représentant légal avant de naviguer sur le
                        site.</p>

                    <h2>ARTICLE 6 : CONDITIONS DE MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITÉ</h2>

                    <p>La présente politique de confidentialité peut être consultée à tout moment à l'adresse ci-après
                        indiquée :</p>

                    <p>________</p>

                    <p>L'éditeur du site se réserve le droit de la modifier afin de garantir sa conformité avec le droit
                        en vigueur.</p>

                    <p>Par conséquent, l'utilisateur est invité à venir consulter régulièrement cette politique de
                        confidentialité afin de se tenir informé des derniers changements qui lui seront apportés.</p>

                    <p>Il est porté à la connaissance de l'utilisateur que la dernière mise à jour de la présente
                        politique de confidentialité est intervenue le : 06/07/2021.</p>
                </div>
            </div>
        </div>


    );
}
export default PDC;