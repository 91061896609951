import React, {useState} from "react";
import NavbarContext from "../context/navbarContext";
import ThemeContext from "../context/ThemeContext";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Navbar from "./Navbar";
import Telechargement from "./Telechargement";
import Formulaire from "./Formulaire";
import FAQ from "./FAQ";
import Accueil from "./Accueil";
import CGU from "./CGU";
import PDC from "./PDC";
import Footer from "./Footer";
import Redirect from "./Redirect";
import Validation from "./Validation";
import Redirection from './Redirection';
import Password from "./Password";
import NotFound from "./NotFound";
import JeuConcours from "./JeuConcours";

function App() {

    const [hauteurNavbar, setHauteurNavbar] = useState(false);
    const [darkMode, setDarkMode] = useState(false);

    return (
        <NavbarContext.Provider value={{hauteurNavbar, setHauteurNavbar}}>
            <ThemeContext.Provider value={{darkMode, theme: (value) => setDarkMode(value)}}>
                <Router>
                    <header>
                        <Navbar/>
                    </header>
                    <Routes>
                        <Route path="/Telecharger" element={<Telechargement/>}/>
                        <Route path="/contactez-nous" element={<Formulaire/>}/>
                        <Route path="/faq" element={<FAQ/>}/>
                        <Route path="/" element={<Accueil/>}/>
                        <Route path="/cgu" element={<CGU/>}/>
                        <Route path="/privacy-policy" element={<PDC/>}/>
                        <Route path="/telecharger-bopper" element={<JeuConcours />}/>
                        <Route path="/validation/:validationString" element={<Validation/>}/>
                        <Route path="/redirect" element={<Redirection />}/>
                        <Route path="/password/:forgottenSlug" element={<Password />}/>
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                    <Footer/>
                </Router>
            </ThemeContext.Provider>
        </NavbarContext.Provider>
    );
}

export default App;
