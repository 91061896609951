import React, {useContext} from 'react'
import '../css/concours.css'
import BopperLogo from "../assets/bopper-logo-white.webp";
import NavbarContext from "../context/navbarContext";
import EtapeConcours from "./EtapeConcours";

const detectOs = () => {
    const OS = ['Android', 'iPhone', 'Windows', 'Mac', 'Linux']
    const ua = window.navigator.userAgent
    for(const currOs of OS) {
        if (ua.toLowerCase().includes(currOs.toLowerCase())) {
            return currOs;
        }
    }
}

const JeuConcours = () => {

    const {hauteurNavbar} = useContext(NavbarContext);
    const currentOs = detectOs();

    const redirectToStore = () => {
        window.location = currentOs.toLowerCase() === 'iPhone'.toLowerCase() || currentOs.toLowerCase() === 'Mac'.toLowerCase() ? 'https://apps.apple.com/fr/app/bopper/id1578669466' : 'https://play.google.com/store/apps/details?id=fr.bopper&gl=FR'
    }

    return (
        <main id={"container-concours"} style={{paddingTop: hauteurNavbar + 50}}>
            <img
                src={BopperLogo}
                alt="Le logo"
                id="logo-bopper"
            />

            <h2>Jeu concours</h2>

            <p className={'neon'}>GAGNE UN BAPTÊME EN <br/> CAYMAN S 981*</p>

            <p id={'dates'}>DU <span className={'bold-text'}>25 AU 27</span> NOVEMBRE 2022</p>

            <div style={{padding: 10}}>
                <div className={'etapes'}>
                    <EtapeConcours numEtape={'1'}>
                        <p>SCAN le QR Code <br/> sur la voiture <br/> pour <span className={'bold-text'}>télécharger</span> l’application</p>
                    </EtapeConcours>

                    <EtapeConcours numEtape={'2'}>
                        <p>POSTE une photo <br/> de ton véhicule <br/> sur <span className={'bold-text'}>l’application <br/> Bopper</span></p>
                    </EtapeConcours>

                    <EtapeConcours numEtape={'3'}>
                        <p>FOLLOW <br/> la page Instagram <br/> de <span className={'bold-text'}>Bopper</span></p>
                    </EtapeConcours>

                    <EtapeConcours numEtape={'4'}>
                        <p>FOLLOW <br/> la page Instagram <br/> de <span className={'bold-text'}>MCW</span></p>
                    </EtapeConcours>
                </div>
            </div>


            <div className={'info'}>
                <p id={'tirage'}>Tirage au sort <br/>le jeudi <span className={'bold-text'}>1er décembre à 18h</span></p>
            </div>

            <button id={'dl'} onClick={redirectToStore}>Télécharger Bopper</button>
            <p id={'doubleChance'}>Psst : Double tes chances si tu es certifié sur l'appli</p>
            <p className={'italic'}>*Voir condition avec le staff sur place</p>

            <img src={'mcwxbopper.png'} id={'mcwxbopper'}/>
        </main>
    );
}

export default JeuConcours;