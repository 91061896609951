import React, {useContext, useEffect} from "react";
import '../css/Section.css'
import CardexLight from '../assets/cardex_light.webp';
import CardexDark from '../assets/cardex_dark.webp';
import {useMediaQuery} from "react-responsive";
import AOS from "aos";
import ThemeContext from "../context/ThemeContext";

function SectionCardex(props) {
    const isMobile = useMediaQuery({query: `(max-width: 811px)`});
    const {darkMode} = useContext(ThemeContext);


    useEffect(() => {
        AOS.init({duration: 1000, once: true, offset: 50});
    }, [])

    return (
        <section className={darkMode ? "toutes-sections section2-dark" : "toutes-sections section2"}>

            {
                props.showImg &&
                <img id={'img-section-cardex'} className={darkMode ? "img-section5" : "img-section5"} src={darkMode ? CardexDark : CardexLight}
                     height="500" alt={"Test drive"}/>
            }
            <div className="div-section2" style={{background: darkMode ? "rgb(33, 37, 41)" : 'white'}}>
                <h1 data-aos={props.animation} className="titre-section2"
                    style={{marginBottom: isMobile ? '' : '5%'}}>LE CARDEX</h1>
                <p data-aos={props.animation} className="texte-section2"
                   style={{marginBottom: isMobile ? '' : '5%'}}>
                    Nous avons tous une âme de <b>CarSpotter</b> au fond de nous ! Ne nous dites pas que vous ne sortez pas votre appareil photo ou votre téléphone à la vue d'une belle voiture, nous savons tout ! Et oui, il s’agit indirectement du <b>car-spotting</b>.
                    <br/><br/>
                </p>
                <p data-aos={props.animation} className={"texte-section2"} style={{fontWeight: 'normal'}}>
                    Grâce au <b>CarDex</b>, vous pouvez <b>collectionner</b> les véhicules les plus prestigieux en prenant en photo toutes les marques et modèles dans le monde et ainsi, vous faire une <b>réputation</b> dans le <b>milieu automobile</b>.
                </p>
            </div>
        </section>
    );
}

export default SectionCardex;
