import React, {useContext, useEffect, useLayoutEffect, useState} from "react";
import FlashMessage from "react-flash-message";
import Lottie from "lottie-react";
import mailError from "../assets/wrong-sent.json";
import navbarContext from "../context/navbarContext";
import {useParams} from "react-router-dom";
import ImgDark from "../assets/img-form-dark.webp";
import Image from "../assets/img-form.webp";
import {useMediaQuery} from "react-responsive";
import ThemeContext from "../context/ThemeContext";
import  '../css/Formulaire.css'

function Validation() {
    const [form, setForm] = useState({mail: '', secret: ''});
    const [messageSent, setMessageSent] = useState(false);
    const [messageError, setError] = useState(false);
    const [messageError2, setError2] = useState(false);
    const {hauteurNavbar} = useContext(navbarContext);
    const isMobile = useMediaQuery({query: '(max-width: 430px)'})
    const {darkMode} = useContext(ThemeContext)

    const {validationString} = useParams();
    useLayoutEffect(() => {
        document.documentElement.style.scrollBehavior = 'auto';
        setTimeout(() => window.scrollTo(0, 0), 5);
        setTimeout(() => document.documentElement.style.scrollBehavior = 'smooth', 5);
        document.getElementsByTagName('html')[0].classList.remove('overflowHtml');
        if (!isMobile) {
            document.getElementsByTagName('footer')[0].classList.add('footerFixed');
        } else document.getElementsByTagName('footer')[0].classList.remove('footerFixed');

        if (darkMode) {
            document.getElementsByTagName('body')[0].classList.add('dark-body');
            document.getElementsByTagName('html')[0].classList.add('dark-body');
        } else {
            document.getElementsByTagName('body')[0].classList.remove('dark-body');
            document.getElementsByTagName('html')[0].classList.remove('dark-body');
        }
    }, [darkMode, isMobile])

        useEffect(() => {
            checkValidationString()
        },[validationString])

    const checkValidationString = async () => {
        const response = await fetch("https://api.bopper.fr/api/users/checkvalidationstring?validationString="+validationString)
        if (response.status === 410) {
            setError2(true)
            setTimeout(()=>{window.location.replace("https://bopper.fr")},2000)
        }
        if (response.status !==200) {
            setError(true)
            setTimeout(()=>{window.location.replace("https://bopper.fr")},2000)
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const url = "https://api.bopper.fr/api/users/validate?mail=" + form.mail + "&secret=" + form.secret
        const mailResponse = await fetch(url)
        console.log(mailResponse.status)
        if (mailResponse.status === 200) {
            setMessageSent(true);
            setForm({mail: '', secret: ''});
        } else {
            if (mailResponse.status === 410) {
                setError2(true);
            }
            else {
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 2200);
            }
        }
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    const Message = () => (

        <FlashMessage duration={3900}>
            <div className="flash-message">
                <div className="div-message">
                    VOTRE COMPTE A ÉTÉ VALIDÉ AVEC SUCCÈS !
                </div>
            </div>
        </FlashMessage>
    )
    const MessageError2 = () => (

        <FlashMessage duration={2200}>
            <div className="div-message-error" style={{marginTop: hauteurNavbar}}>
                <div className="div-animation">
                    <Lottie animationData={mailError}/>
                </div>
                <p className="message-error">
                   Ce compte a déjà été validé
                </p>
            </div>
        </FlashMessage>
    )


    const MessageError = () => (

        <FlashMessage duration={2200}>
            <div className="div-message-error" style={{marginTop: hauteurNavbar}}>
                <div className="div-animation">
                    <Lottie animationData={mailError}/>
                </div>
                <p className="message-error">
                    Ce code ne correspond à aucun compte.
                </p>
            </div>
        </FlashMessage>
    )

    const sendForm = () => {
        const button = document.getElementsByClassName("send-mobile-form")[0];
        button.click();
    }


    return (
        <div className={darkMode ? "bg-dark" : "bg-light"} style={{height: isMobile && "100vh"}}>
            {messageSent && <Message/>}

            {messageError && <MessageError/>}

            {messageError2 && <MessageError2/>}
            <div className={darkMode ? "titre-form-dark" : "titre-form"}
                 style={{display: isMobile ? "flex" : 'none', top: hauteurNavbar}}>
                <h1 style={{marginRight: "2%"}}>Valider votre compte</h1>
                <button className="btn-form" onClick={sendForm}>Envoyer</button>
            </div>
            <div style={{height: isMobile ? "40%" : '100%'}}
                 className={darkMode ? "div-img-form-dark bg-dark" : "div-img-form bg-light"}>
                <img className="img-form" alt={"contact"}
                     src={darkMode ? ImgDark : Image}
                />
            </div>
            <div className={!isMobile && "form-validation"}>
                <div className={darkMode ? "div-form-dark" : "div-form"}
                     style={{
                         marginTop: !isMobile ? hauteurNavbar : '',
                         marginBottom: !isMobile ? hauteurNavbar : '',
                         right: 'auto'
                     }}>


                    <form onSubmit={handleSubmit} className="form" style={{display: isMobile ? 'none' : "block"}}>
                        <h1 className={darkMode ? "titre-form-dark" : "titre-form"}>Valider votre compte</h1>
                        <div id="mail" style={{height: '100%'}}>
                            <div className="form__group field">
                                <input type="email"
                                       id="mail_mail"
                                       name="mail"
                                       required="required"
                                       className={darkMode ? "form__field_dark" : "form__field"}
                                       value={form.mail}
                                       placeholder="Adresse mail"
                                       onChange={handleChange}
                                />
                                <label htmlFor="mail_mail"
                                       className={darkMode ? "required form__label_dark" : "required form__label"}>Adresse
                                    mail</label>
                            </div>

                            <div className="form__group field">
                                <input type="number"
                                       id="mail_secret"
                                       name="secret"
                                       required="required"
                                       className={darkMode ? "form__field_dark" : "form__field"}
                                       value={form.secret}
                                       placeholder="1234"
                                       onChange={handleChange}
                                />
                                <label htmlFor="mail_subject"
                                       className={darkMode ? "required form__label_dark" : "required form__label"}>Code
                                    secret</label>
                            </div>

                            <div>
                                <button type="submit" id="mail_save" name="mail[save]"
                                        className="btn btn-form form_button">Valider
                                </button>
                            </div>
                            <input type="hidden" id="mail__token" name="mail[_token]"
                                   value="a97c79a2.Z8gZdLgin9p-HuQr92spRxUntt_w_VdjHPFuWU1C7ko.D5ApJIBN8ZkLKZx_gllBH1QX3qy8lzURL8EFLCckqiYTi08ZiFDT6hZN0A"/>
                        </div>
                    </form>

                    <form className={darkMode ? "bg-dark" : "bg-light"} onSubmit={handleSubmit} style={{
                        display: isMobile ? 'block' : "none",
                        background: darkMode ? "#212529" : "transparent"
                    }}>
                        <div className={darkMode ? "mobile-form-dark" : "mobile-form"}>

                            <div id="mail" style={{height: '100%'}}>
                                <input type="email"
                                       name="mail"
                                       required="required"
                                       className={darkMode ? "mobile-form-input-dark" : "mobile-form-input"}
                                       value={form.mail}
                                       placeholder="Adresse mail"
                                       onChange={handleChange}
                                />
                                <hr/>
                                <input type="text"
                                       name="secret"
                                       required="required"
                                       className={darkMode ? "mobile-form-input-dark" : "mobile-form-input"}
                                       value={form.secret}
                                       placeholder="1234"
                                       onChange={handleChange}
                                />
                            </div>
                            <button type="submit" id="mail_save" name="mail[save]" style={{display: "none"}}
                                    className={"send-mobile-form"}>
                            </button>
                        </div>
                        <br/>

                        <input type="hidden" id="mail__token" name="mail[_token]"
                               value="a97c79a2.Z8gZdLgin9p-HuQr92spRxUntt_w_VdjHPFuWU1C7ko.D5ApJIBN8ZkLKZx_gllBH1QX3qy8lzURL8EFLCckqiYTi08ZiFDT6hZN0A"/>
                    </form>
                </div>
            </div>
        </div>

    );

}

export default Validation;
