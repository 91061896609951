import Image from '../assets/test-photo-groupe-content.webp';
import imgDark from '../assets/carmeeting-dark.webp'
import '../css/Section.css'
import React, {useContext, useEffect} from "react";
import navbarContext from "../context/navbarContext";
import {useMediaQuery} from "react-responsive";
import AOS from "aos";
import ThemeContext from "../context/ThemeContext";

function SectionNotreObjectif(props){
    const {hauteurNavbar} = useContext(navbarContext);
    const isMobile = useMediaQuery({query: `(max-width: 811px)`});
    const {darkMode} = useContext(ThemeContext);


    useEffect(()=>{
        AOS.init({duration:1000,  once: true, offset:50});
    },[])

    return (
        <section id={"section-objectif-content"} className={darkMode?"toutes-sections section4-dark":"toutes-sections section4"} style={{flexDirection: isMobile?'':'row-reverse'}} >
            <img alt="notre objectif" className={darkMode?"img-section4":"img-section4"} src={darkMode?imgDark:Image} height="500"/>

            <div data-aos={props.animation} className="div-section4">
                <h1 data-aos={props.animation} className="titre-section2" style={{marginBottom:isMobile?'':'5%'}}>LE RESEAU SOCIAL</h1>
                <p data-aos={props.animation} className="texte-section2" style={{marginBottom:isMobile?'':'5%'}}>
                    <b>Découvrez</b> et <b>partagez</b> des voitures, événements et lieux exceptionnels autour de chez vous. <b>Restez au courant</b> des dernières photos, vidéos, événements et messages de vos abonnés, groupes et super star automobiles préférés. Découvrez de nouveaux contenus grâce au <b>fil d'actualité</b> intégré.
                    <br/>
                    <br/>
                    Bopper dispose d’une puissante fonctionnalité de recherche qui vous aidera pour trouver d'autres clubs de voitures et des passionnés qui partagent <b>vos marques préférées et vos intérêts</b>.
                </p>
            </div>

        </section>

    );
}

export default SectionNotreObjectif;
