import React, {useLayoutEffect, useState} from "react";

const EtapeConcours = ({children, numEtape}) => {

    const [etapeHeight, setEtapeHeight] = useState(200);
    useLayoutEffect(() => {
        setEtapeHeight(document.getElementsByClassName('etape').item(numEtape-1).clientWidth)
    }, [])

    return (
        <div className={'etape'} style={{height: etapeHeight}}>
            <div className={'etape-text'}>
                {children}
            </div>
            <div className={'num-etape'} style={{fontSize: etapeHeight}}>{numEtape}</div>
        </div>
    )
}

export default EtapeConcours;