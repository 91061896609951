import React, {useContext, useEffect} from "react";
import '../css/Section.css'
import Image from '../assets/carmeeting.webp';
import imgDark from '../assets/test-drive-dark.webp'
import {useMediaQuery} from "react-responsive";
import AOS from "aos";
import ThemeContext from "../context/ThemeContext";

function SectionTestDrive(props) {
    const isMobile = useMediaQuery({query: `(max-width: 811px)`});
    const {darkMode} = useContext(ThemeContext);


    useEffect(() => {
        AOS.init({duration: 1000, once: true, offset: 50});
    }, [])

    return (
        <section className={darkMode ? "toutes-sections section2-dark" : "toutes-sections section2"}>

            {
                props.showImg &&
                <img id={'img-section-td'} className={darkMode ? "img-section2" : "img-section2"} src={darkMode ? imgDark : Image}
                     height="500" alt={"Test drive"}/>
            }
            <div className="div-section2" style={{background: darkMode ? "rgb(33, 37, 41)" : 'white'}}>
                <h1 data-aos={props.animation} className="titre-section2"
                    style={{marginBottom: isMobile ? '' : '5%'}}>LE TEST-DRIVE</h1>
                <p data-aos={props.animation} className="texte-section2"
                   style={{marginBottom: isMobile ? '' : '5%'}}>
                    Nous aimons le design soigné, la sonorité d’un moteur, les accélérations, les virages, la gomme
                        brulée, les passages de vitesse mais également le sourire des gens, les cris d’admiration, les
                    pouces levés et les discussions de passionnés. Dans le <b>test-drive</b>, tout est réuni !
                    <br/><br/>
                </p>
                <p data-aos={props.animation} className={"texte-section2"} style={{fontWeight: 'normal'}}>
                    Le <b>test-drive</b> est un moment de partage puisqu’il s’agit d’un côté : de concrétiser une envie
                    d’essayer, et de l’autre : de <b>transmettre ses connaissances</b> et mettre en avant son véhicule. Les
                    raisons de l’essai peuvent être multiples : soit de <b>concrétiser un achat</b>, soit pour comparer avec
                    d’autres modèles ou alors par <b>pur plaisir</b>.
                    <br/><br/>
                    Vous pouvez à travers l’application réserver les <b>véhicules d’essai</b> dans les différents
                    <b> concessionnaires</b> en France, mais également les véhicules de nos utilisateurs. Lors de cette
                    rencontre, vous discuterez du véhicule, mais aussi la possibilité de faire votre propre idée soit au
                    volant, soit en tant que passager lors de ce moment unique.
                    <br/><br/>
                    Notre objectif ultime auprès de notre communauté ? Sillonner la France avec notre équipe avec des
                    véhicules de prestige et permettre à nos utilisateurs de les <b>essayer en toute simplicité</b>.
                </p>
            </div>
        </section>
    );
}

export default SectionTestDrive;
